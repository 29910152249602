import MainContent from "./layout/MainContent";
import EditableSection from "./contentEditing/EditableSection";
import { observer } from "mobx-react-lite";
import { useEditablePage, usePageUri } from "lib/content";
import { ContentItem } from "types/content";
import WindowTitle from "./WindowTitle";
import { useEffect, useRef, useState } from "react";
import FormattedText from "./FormattedText";
import { useContentEditStore, useMember } from "lib/gf-app-context";
import { memberHasRoleAccess } from "lib/auth";
import Button from "./Button";
import Style from "lib/Style";
import clsx from "clsx";
import { useThemeInfo } from "lib/theme";
import ServerError404 from "./serverErrors/404";
interface Props {
  route?: string;
}
const FAVICON = "/favicon.ico";
function CMSPage(props: Props) {
  const fullUri = props.route || usePageUri();
  const [storyPath, anchorId] = fullUri.split("#");
  if (!storyPath) return null;
  if (storyPath === FAVICON) return null;
  const {
    getPage
  } = useEditablePage({
    uri: storyPath,
    onLoad: onPageLoad
  });
  const [notFound, setNotFound] = useState<boolean>(false);
  const user = useMember();
  const editStore = useContentEditStore();
  const canEdit = user && memberHasRoleAccess(user.account, "content");
  const themeInfo = useThemeInfo();
  const scrolledRef = useRef(false);

  // Scroll to anchor if present
  useEffect(() => {
    if (anchorId && !scrolledRef.current) {
      const anchor = document.getElementById(anchorId);
      if (anchor) {
        anchor.scrollIntoView();
        scrolledRef.current = true;
      }
    }
  });
  function onPageLoad(page: ContentItem | null) {
    if (!page) {
      console.warn(">> NOTHING FOUND FOR", storyPath);
      if (typeof window !== "undefined") {
        setNotFound(true);
      }
    }
  }
  function onAddClick() {
    editStore?.edit({
      baseType: "text",
      subType: "page",
      uri: storyPath,
      plurality: "item"
    }, "add");
  }
  const page = getPage();
  let cardStyle = "";
  if (themeInfo.themeArea === "members") {
    cardStyle = Style.cardMajor;
  }
  return <>
      <WindowTitle title={page?.title} data-sentry-element="WindowTitle" data-sentry-source-file="CMSPage.tsx" />
      <MainContent data-sentry-element="MainContent" data-sentry-source-file="CMSPage.tsx">
        {page && page.content && <EditableSection contentId={page.id} className={clsx(cardStyle, "mx-2 lg-mx-0 p-4")}>
            <FormattedText text={page.content} contentType={page.contentType} />
          </EditableSection>}
        {notFound && !page?.content && <>
            <ServerError404 />
            {canEdit && <Button onClick={onAddClick}>Add page</Button>}
          </>}
      </MainContent>
    </>;
}
export default observer(CMSPage);