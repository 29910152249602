import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
interface Props {
  onClick?: () => void;
  svgClass?: string;
  textClass?: string;
}
export default function CloseButton(props: Props) {
  const {
    onClick,
    svgClass
  } = props;
  const textClass = props.textClass || "text-gray-darker hover:text-black";
  return <button className={textClass} onClick={onClick} data-sentry-component="CloseButton" data-sentry-source-file="CloseButton.tsx">
      <XMarkIcon className={clsx("h-6 w-6 transition", svgClass)} data-sentry-element="XMarkIcon" data-sentry-source-file="CloseButton.tsx" />
    </button>;
}