// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ListItem from "components/sortableList/ListItem";
import { SyntheticEvent } from "react";
import { UseSortableReturn } from "types/sortable";
interface Props {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  onClick?: (e: SyntheticEvent) => void;
  sortable?: UseSortableReturn;
  style?: any;
  title?: string;
}
export default function ListItemWrapper(props: Props) {
  const {
    sortable,
    children,
    className,
    id,
    onClick,
    style,
    title
  } = props;
  return <ListItem className={`ListItemWrapper block border rounded mb-2 hover:border-new-gray-500 flex items-center gap-1 ${className || ""}`} id={id} onClick={onClick} sortable={sortable} style={style} title={title} data-sentry-element="ListItem" data-sentry-component="ListItemWrapper" data-sentry-source-file="ListItemWrapper.tsx">
      {children}
    </ListItem>;
}