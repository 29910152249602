import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useNavItem } from "hooks/content";
import { useChannelStore, useHamburgerStore, useLoginModalStore, useMember } from "lib/gf-app-context";
import { DEFAULT_LOGGED_OUT_PRIMARY_NAV_ITEMS } from "../constants";
import EditableSection from "components/contentEditing/EditableSection";
import { ContentBaseType } from "types/content";
import { filterItemsForLoggedInStatus } from "lib/nav";
import { Fragment, useEffect } from "react";
import { NavItem, NavigationPurpose } from "types/nav";
import { CountBadge } from "components/ui";
import { UserMenuButton } from "./UserMenuButton";
import clsx from "clsx";
import MessageIcon from "components/icon/MessageIcon";
import { UserCircleIcon } from "@heroicons/react/24/outline";
interface Props {
  accountMenuInline?: boolean;
  className?: string;
  navMenuId?: string;
  navMenuSlug?: string;
  parentTag?: string;
  purpose?: NavigationPurpose;
  style?: any;
}
function Navigation(props: Props) {
  const {
    className,
    navMenuId,
    navMenuSlug,
    parentTag,
    purpose,
    style
  } = props;
  const {
    getNavMenu,
    getNavMenuById
  } = useNavItem();
  let navMenu = navMenuId ? getNavMenuById(navMenuId) : undefined;
  if (!navMenu && navMenuSlug) {
    navMenu = getNavMenu(navMenuSlug);
  }
  const member = useMember();
  let editById: undefined | {
    id: string;
    baseType: ContentBaseType;
  };
  let editBySlug: undefined | {
    slug: string;
    baseType: ContentBaseType;
  };
  const channelStore = useChannelStore();
  const unreadCount = channelStore?.unreadCount ?? 0;
  const store = useHamburgerStore();
  const loginModalStore = useLoginModalStore();
  useEffect(() => {
    if (navMenu && store && purpose === "primary") {
      store.setNavItemId(navMenu.id);
    }
  }, [navMenuId, navMenuSlug]);
  function renderLink(item: NavItem) {
    return <Link href={item.uri} key={item.uri} className={clsx(parentTag, "Link", className, "transition")} data-sentry-element="Link" data-sentry-component="renderLink" data-sentry-source-file="Navigation.tsx">
        {item.label}
      </Link>;
  }
  function onLoginClick(ev: React.MouseEvent) {
    ev.preventDefault();
    loginModalStore?.open();
  }
  function renderItem(item: NavItem) {
    if (item.settings?.isAccountMenu) {
      return <UserMenuButton menuIsInline={props.accountMenuInline} />;
    } else if (item.uri === "/login") {
      return <Link href="/login" className="Link flex items-center gap-2 relative" onClick={onLoginClick}>
          {purpose === "primary" ? <>
              <UserCircleIcon className="w-6 h-6" />
              <span>Login</span>
            </> : <span>Login</span>}{" "}
        </Link>;
    } else if (item.uri === "/members/messages") {
      let badgeClass = "";
      if (purpose === "primary") {
        badgeClass = "absolute translate-x-3 -translate-y-2";
      }
      return <Link href="/members/messages" className="Link flex items-center gap-2 relative">
          {purpose === "primary" ? <MessageIcon className={clsx("w-[1.3rem] h-[1.3rem]", unreadCount > 0 ? "translate-y-1" : "translate-y-0.5")} /> : <span>Messages</span>}{" "}
          {unreadCount > 0 && <CountBadge className={badgeClass}>{unreadCount}</CountBadge>}
        </Link>;
    }
    return renderLink(item);
  }
  if (navMenu) {
    editById = {
      baseType: "nav",
      id: navMenu.id
    };
  } else if (navMenuSlug) {
    editBySlug = {
      baseType: "nav",
      slug: navMenuSlug
    };
  }
  let items: NavItem[];
  if (navMenu) {
    items = filterItemsForLoggedInStatus((navMenu.children as NavItem[]), member?.account?.status, member?.account?.roles).map(item => ({
      uri: item.uri || "/",
      label: item.label,
      settings: item.settings,
      slug: item.slug,
      role: item.settings?.role
    }));
  } else {
    items = DEFAULT_LOGGED_OUT_PRIMARY_NAV_ITEMS.map(raw => ({
      label: raw.text,
      uri: raw.url
    }));
  }
  if (!items || items.length === 0) {
    return null;
  }
  return <EditableSection className={`Navigation ${className || ""}`} editById={editById} editBySlug={editBySlug} style={style} data-sentry-element="EditableSection" data-sentry-component="Navigation" data-sentry-source-file="Navigation.tsx">
      {items.map(m => <Fragment key={m.uri}>{renderItem(m)}</Fragment>)}
    </EditableSection>;
}
export default observer(Navigation);