// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import clsx from "clsx";
import { ADMIN_STYLE } from "lib/Style";
import { ThemeArea } from "types/theme";
interface Props {
  className?: string;
  children: React.ReactNode;
  themeArea?: ThemeArea;
}
export default function FieldLabel(props: Props) {
  const {
    className,
    themeArea,
    ...rest
  } = props;
  let themeAreaClass = "";
  if (themeArea === "admin") {
    themeAreaClass = ADMIN_STYLE.fieldLabel;
  } else {
    themeAreaClass = "field-label";
  }
  return <label className={clsx(themeAreaClass, className)} {...rest} data-sentry-component="FieldLabel" data-sentry-source-file="FieldLabel.tsx">
      {props.children}
    </label>;
}