// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ColumnLabel, FormRow, TextInput } from "components/form";
import { ReactElement } from "react";
import { UseFormReturn } from "react-hook-form";
import types from "types/field";
export interface DynamicOtherTextFieldProps {
  component: types.Element;
  fieldRow?: types.FieldRow;
  questionId: string;
  hookForm: UseFormReturn<any>;
}
export function DynamicOtherTextField(props: DynamicOtherTextFieldProps): ReactElement {
  const {
    fieldRow,
    hookForm: {
      register
    },
    questionId
  } = props;
  const label = fieldRow?.label;
  return <FormRow className="flex gap-4 items-center" data-sentry-element="FormRow" data-sentry-component="DynamicOtherTextField" data-sentry-source-file="DynamicOtherTextField.tsx">
      <ColumnLabel style={{
      width: "6em",
      minWidth: "6em",
      maxWidth: "6em"
    }} className="flex-grow" data-sentry-element="ColumnLabel" data-sentry-source-file="DynamicOtherTextField.tsx">
        {label?.value || "Other"}
      </ColumnLabel>
      <TextInput register={register && register(`answers.${questionId}.otherValue`)} data-sentry-element="TextInput" data-sentry-source-file="DynamicOtherTextField.tsx" />
    </FormRow>;
}