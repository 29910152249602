// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ContentItem } from "types/content";
import { UseSortableReturn } from "types/sortable";
import ContentItemTypeIcon from "./ContentItemTypeIcon";
import DeleteCol from "./listItem/DeleteCol";
import GripCol from "./listItem/GripCol";
import LabelCol from "./listItem/LabelCol";
import ListItemWrapper from "./listItem/ListItemWrapper";
import StatusIcon from "./StatusIcon";
interface Props {
  sortable?: UseSortableReturn;
  contentItem: ContentItem;
  onClick?: (contentItem: ContentItem) => void;
  onDelete?: (contentItem: ContentItem) => void;
}
export default function TextListItem(props: Props) {
  const {
    sortable,
    contentItem,
    onClick
  } = props;
  function onDelete(e: any) {
    e.preventDefault();
    e.stopPropagation();
    props.onDelete && props.onDelete(contentItem);
  }
  return <ListItemWrapper id={`nav-item-${contentItem.id}`} title={`ID: ${contentItem.id}`} onClick={() => {
    onClick && onClick(contentItem);
  }} sortable={sortable} data-sentry-element="ListItemWrapper" data-sentry-component="TextListItem" data-sentry-source-file="TextListItem.tsx">
      <GripCol data-sentry-element="GripCol" data-sentry-source-file="TextListItem.tsx" />
      <ContentItemTypeIcon contentItem={contentItem} data-sentry-element="ContentItemTypeIcon" data-sentry-source-file="TextListItem.tsx" />
      <StatusIcon contentItem={contentItem} data-sentry-element="StatusIcon" data-sentry-source-file="TextListItem.tsx" />
      <LabelCol data-sentry-element="LabelCol" data-sentry-source-file="TextListItem.tsx">
        <span className="inline-block" title={`ID: ${contentItem.id}`}>
          {contentItem.title}
        </span>
        {contentItem.internalName && <span className="text-gray-dark inline-block truncate ml-2">
            - {contentItem.internalName}
          </span>}
      </LabelCol>
      <DeleteCol onDelete={onDelete} data-sentry-element="DeleteCol" data-sentry-source-file="TextListItem.tsx" />
    </ListItemWrapper>;
}