// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ContentItem, ContentItemParams, ContentPlurality } from "types/content";
import { RowLabel, FormRow } from "components/form";
import { useForm } from "react-hook-form";
import Style from "lib/Style";
import { useEffect, useState } from "react";
import HelpText from "components/text/HelpText";
import SelectTextItem from "./SelectTextItem";
import { MemberRoles } from "lib/members";
import Select from "components/form/Select";
import SubmitButtonRow from "components/admin/ui/SubmitButtonRow";
interface Props {
  className?: string;
  formValues?: Partial<Form>;
  navItem?: ContentItem;
  onClose?: () => void;
  onSave?: (navItem: Partial<ContentItemParams>) => void;
}
interface Form {
  internalName: string;
  isAccountMenu: boolean;
  isPublished: boolean;
  label: string | null;
  plurality: ContentPlurality;
  role: string | null;
  rootUri: string | null;
  settings: {
    isAccountMenu: boolean;
    isLoggedInVisible: boolean;
    isLoggedOutVisible: boolean;
    isMobileVisible: boolean;
    role: string | null;
  };
  slug: string | undefined | null;
  uri: string | null;
}
export default function NavItemForm(props: Props) {
  const {
    className,
    formValues,
    navItem,
    onClose,
    onSave
  } = props;
  const plurality = ((navItem?.plurality || formValues?.plurality || "item") as ContentPlurality);
  const {
    register,
    handleSubmit,
    formState,
    setValue
  } = useForm<Form>({
    mode: "all",
    defaultValues: {
      internalName: "",
      isPublished: true,
      settings: {
        isAccountMenu: false,
        isLoggedInVisible: true,
        isLoggedOutVisible: true,
        isMobileVisible: true,
        role: ""
      },
      label: null,
      plurality: "item",
      rootUri: "",
      slug: "",
      uri: null
    }
  });
  const {
    errors
  } = formState;
  const [selectingPage, setSelectingPage] = useState<boolean>(false);
  function onSubmit(data: any) {
    if (!onSave) return;
    let {
      isPublished,
      settings: {
        role,
        ...settings
      },
      ...params
    } = data;
    if (navItem) {
      params = {
        ...params,
        id: navItem.id
      };
    }
    if (isPublished) {
      params = {
        ...params,
        status: "published"
      };
    } else {
      params = {
        ...params,
        status: "draft"
      };
    }
    settings = {
      ...settings,
      role: role || null
    };
    params = {
      ...params,
      settings
    };
    onSave(params);
  }
  function onPageSelected(page: ContentItem) {
    setValue("uri", page.uri);
  }
  useEffect(() => {
    if (navItem) {
      setValue("internalName", navItem.internalName);
      setValue("isPublished", navItem.status === "published");
      setValue("settings.isAccountMenu", navItem.settings.isAccountMenu);
      setValue("settings.isLoggedInVisible", navItem.settings.isLoggedInVisible);
      setValue("settings.isLoggedOutVisible", navItem.settings.isLoggedOutVisible);
      setValue("settings.isMobileVisible", navItem.settings.isMobileVisible);
      setValue("settings.role", navItem.settings.role);
      setValue("label", navItem.label);
      setValue("plurality", navItem.plurality);
      setValue("uri", navItem.uri);
      setValue("rootUri", navItem.rootUri);
      setValue("slug", navItem.slug || "");
    } else if (formValues) {
      Object.keys(formValues).forEach(key => {
        setValue((key as any), (formValues as any)[key]);
      });
    }
  }, [navItem]);
  return <form onSubmit={handleSubmit(onSubmit)} className={`${className || ""} flex flex-col gap-4`} data-sentry-component="NavItemForm" data-sentry-source-file="NavItemForm.tsx">
      {navItem && <FormRow>
          <RowLabel>ID</RowLabel>
          <div>{navItem.id}</div>
        </FormRow>}
      <FormRow data-sentry-element="FormRow" data-sentry-source-file="NavItemForm.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="NavItemForm.tsx">Display label</RowLabel>
        <input type="text" className={Style.inputClass} {...register("label")} />
        {errors.label && <div className="text-red-danger">Error: {errors.label.message}</div>}
      </FormRow>

      <FormRow data-sentry-element="FormRow" data-sentry-source-file="NavItemForm.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="NavItemForm.tsx">Internal name</RowLabel>
        <input type="text" className={Style.inputClass} {...register("internalName")} />
        {errors.internalName && <div className="text-red-danger">
            Error: {errors.internalName.message}
          </div>}
      </FormRow>

      <FormRow data-sentry-element="FormRow" data-sentry-source-file="NavItemForm.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="NavItemForm.tsx">Slug</RowLabel>
        <HelpText data-sentry-element="HelpText" data-sentry-source-file="NavItemForm.tsx">
          This is a distinct name that is used by the website find this
          navigation item. It's usually left blank.
        </HelpText>
        <input type="text" className={Style.inputClass} {...register("slug")} />
        {errors.slug && <div className="text-red-danger">Error: {errors.slug.message}</div>}
      </FormRow>

      {plurality === "list" && <FormRow>
          <RowLabel>Navigation Root URI</RowLabel>
          <HelpText>
            This is the root path for determining whether the navigation should
            display on the current page (based on the page's URI)
          </HelpText>
          <input type="text" className={Style.inputClass} {...register("rootUri")} />
        </FormRow>}

      {plurality === "item" && <FormRow>
          <RowLabel>Page URI</RowLabel>
          <HelpText>This can be a local path, or a full URL</HelpText>
          <input type="text" className={Style.inputClass} {...register("uri")} />
          {!selectingPage && <a className="!text-gf-green-dark !hover:text-gf-green cursor-pointer" onClick={() => setSelectingPage(true)}>
              Select local page...
            </a>}
          <SelectTextItem isOpen={selectingPage} onClose={() => setSelectingPage(false)} onItemSelected={onPageSelected} prompt="Find a page" queryParams={{
        subType: "page",
        parentId: null,
        plurality: "item"
      }} />
          {errors.uri && <div className="text-red-danger">Error: {errors.uri.message}</div>}
        </FormRow>}

      <FormRow data-sentry-element="FormRow" data-sentry-source-file="NavItemForm.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="NavItemForm.tsx">Access role</RowLabel>
        <p className="p text-gray-dark">
          Restrict visibility to the specified member role.
        </p>

        <Select className="border rounded px-2 py-1" register={register("settings.role")} data-sentry-element="Select" data-sentry-source-file="NavItemForm.tsx">
          <option value="">-- None --</option>
          {MemberRoles.filter(r => r.value !== "super").map(role => <option value={role.value} key={role.value}>
              {role.label}
            </option>)}
        </Select>
      </FormRow>

      <FormRow data-sentry-element="FormRow" data-sentry-source-file="NavItemForm.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="NavItemForm.tsx">Options</RowLabel>
        <div className="flex justify-between items-center">
          <input type="checkbox" {...register("isPublished")} id="isPublished" />
          <label htmlFor="isPublished" className="flex-grow ml-2">
            Make item published
          </label>
        </div>
        <div className="flex justify-between items-center">
          <input type="checkbox" {...register("settings.isLoggedInVisible")} id="isLoggedInVisible" />
          <label htmlFor="isLoggedInVisible" className="flex-grow ml-2">
            Visible to logged-in members?
          </label>
        </div>
        <div className="flex justify-between items-center">
          <input type="checkbox" {...register("settings.isLoggedOutVisible")} id="isLoggedOutVisible" />
          <label htmlFor="isLoggedOutVisible" className="flex-grow ml-2">
            Visible to logged-out members?
          </label>
        </div>
        <div className="flex justify-between items-center">
          <input type="checkbox" {...register("settings.isMobileVisible")} id="isMobileVisible" />
          <label htmlFor="isMobileVisible" className="flex-grow ml-2">
            Visible to mobile devices?
          </label>
        </div>
        {plurality === "item" && <div className="flex justify-between items-center">
            <input type="checkbox" {...register("settings.isAccountMenu")} id="isAccountMenu" />
            <label htmlFor="isAccountMenu" className="flex-grow ml-2">
              Is this the Member Account Menu?
            </label>
          </div>}
      </FormRow>

      <SubmitButtonRow cancel={{
      onClick: () => onClose && onClose()
    }} data-sentry-element="SubmitButtonRow" data-sentry-source-file="NavItemForm.tsx">
        Save
      </SubmitButtonRow>
    </form>;
}