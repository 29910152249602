// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  className?: string;
  style?: any;
}
export default function PageIcon(props: Props) {
  const {
    className,
    style
  } = props;
  const otherProps = ({} as any);
  return <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px" className={className} style={style} title="Page icon" {...otherProps} data-sentry-element="svg" data-sentry-component="PageIcon" data-sentry-source-file="PageIcon.tsx">
      <path d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 12 L 15 12 L 15 14 L 29 14 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 18 24 L 18 22 Z M 22 22 L 22 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 18 30 L 18 28 Z M 22 28 L 22 30 L 35 30 L 35 28 Z M 15 34 L 15 36 L 18 36 L 18 34 Z M 22 34 L 22 36 L 35 36 L 35 34 Z" data-sentry-element="path" data-sentry-source-file="PageIcon.tsx" />
    </svg>;
}