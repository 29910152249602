// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ApiError, ApiResponse, RequestError } from "types/api";
import Flash from "components/Flash";
import ActiveMemberRequired from "./admin/members/statusMessaging/ActiveMemberRequired";
interface Props {
  className?: string;
  defaultRequestErrorMessage?: string;
  error: ApiResponse<any> | ApiError | null | undefined;
  messagePrefix?: string;
  serverErrorMessage?: string;
}
export default function ApiErrors(props: Props) {
  const {
    className,
    error: response,
    messagePrefix
  } = props;
  let {
    serverErrorMessage
  } = props;
  serverErrorMessage = serverErrorMessage || "There was a problem handling your request.";
  function renderError(error: RequestError, key: number) {
    let message = `${error.title || ""} ${error.detail || ""}`;
    message = messagePrefix ? `${messagePrefix} ${message}` : message;
    message = message.trim();
    return <Flash key={key} type="warning" className="mb-2" data-sentry-element="Flash" data-sentry-component="renderError" data-sentry-source-file="ApiErrors.tsx">
        {message || "An unrecognized error occurred"}
      </Flash>;
  }
  if (!response || !response.error) return null;
  const error = (response as ApiError);
  if (error.serverError) {
    return <div className="mb-4">
        <Flash>{serverErrorMessage}</Flash>
      </div>;
  }
  const defaultRequestErrorMessage = props.defaultRequestErrorMessage || "Failed to process your request.";
  if (error.memberExpired) {
    return <ActiveMemberRequired />;
  }
  if (!error.requestErrors || error.requestErrors.length === 0) {
    return <Flash type="warning">{defaultRequestErrorMessage}</Flash>;
  }
  return <div className={`mb-4 ${className || ""}`} data-sentry-component="ApiErrors" data-sentry-source-file="ApiErrors.tsx">
      {error.requestErrors.map(renderError)}
    </div>;
}