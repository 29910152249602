/**
 * Deprecated: Use CheckboxWithLabel3 instead.
 */

import FieldOptionLayout from "./FieldOptionLayout";
import FieldRequiredAsterisk from "./FieldRequiredAsterisk";
interface Props {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  label: string | React.ReactNode;
  name?: string;
  onChange?: (e: React.SyntheticEvent) => void;
  register?: any;
  required?: boolean;
  value?: string | boolean;
}
export default function CheckboxWithLabel(props: Props) {
  let {
    checked,
    className,
    onChange,
    register,
    label,
    required,
    value
  } = props;
  value = value === undefined ? true : value;
  let name = props.name;
  let name1 = name;
  if (!name && props.register) {
    const {
      name: name2
    } = props.register;
    if (name2) {
      name1 = name2;
    }
  }
  const disabled = props.disabled || false;
  const inputId = props.id || ["CheckboxWithLabel", name1, value].filter(value => value).join("-");
  let addProps = {};
  if (register) {
    addProps = {
      ...addProps,
      ...register
    };
  }
  if (name) {
    addProps = {
      ...addProps,
      name
    };
  }
  if (checked !== undefined) {
    addProps = {
      ...addProps,
      checked
    };
  }
  if (onChange !== undefined) {
    addProps = {
      ...addProps,
      onChange
    };
  }
  return <FieldOptionLayout className={className} field={<input disabled={disabled} type="checkbox" {...addProps} key={inputId} value={(value as any)} id={inputId} />} label={<label htmlFor={inputId}>
          {label}
          {required ? <>
              {" "}
              <FieldRequiredAsterisk />
            </> : null}
        </label>} data-sentry-element="FieldOptionLayout" data-sentry-component="CheckboxWithLabel" data-sentry-source-file="CheckBoxWithLabel.tsx" />;
}