import clsx from "clsx";
import { ReactNode } from "react";
export default function PageWrapperForSideCar({
  children,
  sideCarShowing
}: {
  children: ReactNode;
  sideCarShowing: boolean;
}) {
  let pageStyle: any = {};
  if (sideCarShowing) {
    pageStyle = {
      transform: "translate3d(-16rem,0,0)"
    };
  }
  return <div className={clsx("PageWrapperForSideCar", sideCarShowing ? "fixed" : "", "gf-page-layout w-full min-h-screen flex flex-col justify-between items-stretch relative")} style={pageStyle} data-sentry-component="PageWrapperForSideCar" data-sentry-source-file="PageWrapperForSideCar.tsx">
      {children}
    </div>;
}