// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ItemSearch from "components/ItemSearch";
import { BackArrow } from "components/ui";
import { useApiCallParams, useContentEditStore, useMemberJwt } from "lib/gf-app-context";
import { listContentItems2 } from "lib/gf-api/content-api";
import { useState } from "react";
import { ContentItem } from "types/content";
import TitleHeading from "./modal/TitleHeading";
import TitleWrapper from "./modal/TitleWrapper";
import NavItemResultItem from "./NavItemResultItem";
import AddIcon from "components/icon/AddIcon";
import Button from "components/Button";
interface Props {
  onClose?: () => void;
  onItemSelected?: (navItem: ContentItem) => void;
}
export default function NavItemsIndex(props: Props) {
  const {
    onClose
  } = props;
  const jwt = useMemberJwt();
  if (!jwt) return null;
  const apiProps = useApiCallParams();
  const [items, setItems] = useState<null | ContentItem[]>(null);
  const contentEditStore = useContentEditStore();
  function onBackClick() {
    onClose && onClose();
  }
  function onSearch(queryString: string) {
    listContentItems2(apiProps, {
      baseType: "nav",
      queryString
    }).then(result => {
      if (!result.error) {
        setItems((result.data as ContentItem[]));
      }
    });
  }
  function onItemSelected(navItem: ContentItem) {
    props.onItemSelected && props.onItemSelected(navItem);
  }
  function onAddNavMenu() {
    contentEditStore?.edit({
      baseType: "nav",
      plurality: "list"
    }, "add");
  }
  return <>
      <TitleWrapper data-sentry-element="TitleWrapper" data-sentry-source-file="NavItemsIndex.tsx">
        <div className="flex justify-between items-center">
          <BackArrow onClick={onBackClick} data-sentry-element="BackArrow" data-sentry-source-file="NavItemsIndex.tsx" />
          <TitleHeading data-sentry-element="TitleHeading" data-sentry-source-file="NavItemsIndex.tsx">Search nav items</TitleHeading>
        </div>
        <div>
          <Button className="text-white" onClick={onAddNavMenu} data-sentry-element="Button" data-sentry-source-file="NavItemsIndex.tsx">
            <AddIcon className="fill-current" data-sentry-element="AddIcon" data-sentry-source-file="NavItemsIndex.tsx" />
            Add Nav Menu
          </Button>
        </div>
      </TitleWrapper>
      <ItemSearch onSearch={onSearch} onItemSelected={onItemSelected} items={items} mapResultItem={item => <NavItemResultItem navItem={item} />} data-sentry-element="ItemSearch" data-sentry-source-file="NavItemsIndex.tsx" />
    </>;
}