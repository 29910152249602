// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ApiErrors from "components/ApiErrors";
import { ErrorMessage, FileInput } from "components/form";
import CheckboxWithLabel from "components/form/CheckBoxWithLabel";
import FormattedText from "components/FormattedText";
import ProgressIndicator from "components/ProgressIndicator";
import { documentOffset, insertTextAtCursor } from "lib/dom";
import { postContentPhoto } from "lib/gf-api";
import { useApiHelper } from "lib/gf-api/api-react";
import React, { useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { TextEditForm } from "types/content";
import { SimpleImageUpload } from "types/photo";
import ImageIcon from "components/icon/ImageIcon";
import Select from "components/form/Select";
interface Props {
  hookForm: UseFormReturn<TextEditForm>;
  register: any;
}
const maskRef: React.RefObject<HTMLInputElement | null> = React.createRef();
const maskCoverRef: React.RefObject<HTMLInputElement | null> = React.createRef();
const fileInputRef: React.RefObject<HTMLInputElement | null> = React.createRef();
export default function ContentTextArea(props: Props) {
  const {
    hookForm,
    register
  } = props;
  const {
    formState,
    watch
  } = hookForm;
  const {
    name
  } = register;
  const textAreaRef = useRef(null);
  const photoUpload = useApiHelper<SimpleImageUpload>();
  const cursorPosRef = useRef(null);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  function onPhotoDrop(ev: any) {
    ev.preventDefault();
    const textAreaEl = textAreaRef.current;
    if (!textAreaEl) return;
    const data = ev.dataTransfer;
    const files = data.files;
    if (files.length === 0) return;
    onPhotoUpload(files[0]);
  }
  async function onPhotoUpload(file: File) {
    const textAreaEl = (textAreaRef.current as any);
    showMask();
    const result = await photoUpload.wrapCall(call => postContentPhoto(call, file));
    if (!result.error) {
      insertPhotoToTextArea(result.data);
    }
    hideMask();
    if (textAreaEl) {
      if (cursorPosRef.current) {
        textAreaEl.selectionEnd = cursorPosRef.current;
      }
      (textAreaEl as any).focus();
    }
  }
  function insertPhotoToTextArea({
    url
  }: {
    url: string;
  }) {
    const textAreaEl = (textAreaRef.current as any);
    if (!textAreaEl) return;
    if (url.endsWith("pdf")) {
      insertTextAtCursor(textAreaEl, `[pdf](${url})`);
    } else {
      insertTextAtCursor(textAreaEl, `![photo](${url})`);
    }
  }
  function onPhotoDragEnter(ev: any) {
    ev.preventDefault();
    showMask();
  }
  function onPhotoDragLeave() {
    hideMask();
  }
  function showMask() {
    const maskEl = maskRef.current;
    const maskCoverEl = maskCoverRef.current;
    if (maskEl && maskCoverEl) {
      maskCoverEl.style.display = "block";
      maskEl.style.display = "flex";
    }
  }
  function hideMask() {
    const maskEl = maskRef.current;
    const maskCoverEl = maskCoverRef.current;
    if (maskEl && maskCoverEl) {
      maskEl.style.display = "none";
      maskCoverEl.style.display = "none";
    }
  }
  function textAreaRefSetup(el: any) {
    if (register?.ref) {
      register.ref(el);
    }
    textAreaRef.current = el;
    setTimeout(() => {
      if (el) {
        const maskEl = maskRef.current;
        const maskCoverEl = maskCoverRef.current;
        if (maskEl && maskCoverEl) {
          const offset = documentOffset(el);
          const width = offset.right - offset.left;
          const height = offset.bottom - offset.top;
          maskCoverEl.style.width = `${width}px`;
          maskCoverEl.style.height = `${height}px`;
          maskEl.style.width = `${width}px`;
          maskEl.style.height = `${height}px`;
        } else {
          console.log("maskEl is not present");
        }
      }
    }, 10);
  }
  function onUploadButtonClick(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
    const inputEl = fileInputRef.current;
    const textAreaEl = (textAreaRef.current as any);
    if (!inputEl) return;
    if (textAreaEl) {
      cursorPosRef.current = textAreaEl.selectionEnd;
    }
    inputEl.click();
  }
  const {
    ref: origRef,
    ...updatedRegister
  } = register || {};
  return <>
      <div className="mb-2 mt-2 flex gap-2">
        <button type="button" className="flex gap-2 border hover:border-secondary-400 rounded px-2 py-1 bg-white" onClick={onUploadButtonClick}>
          <span title="Upload Photo" className="border rounded px-2 py-1">
            <ImageIcon style={{
            width: "16px",
            height: "16px"
          }} data-sentry-element="ImageIcon" data-sentry-source-file="ContentTextArea.tsx" />
          </span>
          Add image or PDF
        </button>
        <label className="border rounded px-2 py-1 bg-white hover:border-secondary-400" htmlFor="content-preview-checkbox">
          <CheckboxWithLabel id="content-preview-checkbox" label="Preview" value="preview" checked={isPreview} onChange={() => setIsPreview(!isPreview)} data-sentry-element="CheckboxWithLabel" data-sentry-source-file="ContentTextArea.tsx" />
        </label>
        <Select register={hookForm.register("contentType")} options={[{
        value: "text/markdown",
        label: "Markdown"
      }, {
        value: "text/html",
        label: "HTML"
      }]} data-sentry-element="Select" data-sentry-source-file="ContentTextArea.tsx" />
      </div>
      <FileInput additionalAccepts={["application/pdf", "video/mpeg"]} inputRef={fileInputRef} onChange={onPhotoUpload} className="hidden" data-sentry-element="FileInput" data-sentry-source-file="ContentTextArea.tsx" />

      <div className="relative">
        {false && <>
            <textarea />
            <ErrorMessage name="descriptionVisual" errors={formState.errors} />
            <p className="text-gray-darker text-sm">
              Hint: to make a single line break without any space between two
              lines, hold the Shift key while you press Enter.
            </p>
          </>}

        {true && <>
            {isPreview ? <div className="overflow-y-auto border rounded px-3 py-1" style={{
          height: "530px"
        }}>
                <FormattedText format={watch("contentType") === "text/html" ? "html" : "markdown"} text={watch(name)} />
              </div> : <textarea className="border border-gray text-gray-darkest overflow-auto p-6 w-full" placeholder="page text..." rows={20} style={{
          fontFamily: 'SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace'
        }} ref={textAreaRefSetup} {...updatedRegister} onDragEnter={onPhotoDragEnter} />}
          </>}

        <div className="mask absolute flex justify-center items-center" style={{
        display: "none",
        backgroundColor: "rgba(220, 220, 220, 0.75)",
        width: "300px",
        height: "300px",
        left: "0",
        top: "0"
      }} ref={maskRef}>
          <div className="text-xl bg-white p-4 rounded">
            {photoUpload.processing ? <ProgressIndicator /> : "Drop Photo Here"}
          </div>
        </div>
        <div className="cover absolute" style={{
        display: "none",
        backgroundColor: "rgba(255, 255, 255, 0.0)",
        width: "300px",
        height: "300px",
        left: "0",
        top: "0"
      }} ref={maskCoverRef} onDragEnter={onPhotoDragEnter} onDragLeave={onPhotoDragLeave} onDrop={onPhotoDrop} onDragOver={ev => ev.preventDefault()} />
        <ApiErrors error={photoUpload.errorResponse} data-sentry-element="ApiErrors" data-sentry-source-file="ContentTextArea.tsx" />
      </div>
    </>;
}