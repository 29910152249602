// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { useContext, useState } from "react";
import Link from "next/link";
import { NavItem } from "types/nav";
import { MpAppContext } from "lib/gf-app-context";
import { Me } from "types/member";
import MemberPhoto from "components/member/MemberPhoto";
import { observer } from "mobx-react-lite";
import { memberCanSeeAdmin } from "lib/auth";
import { UserCircleIcon } from "@heroicons/react/24/outline";
function Item(props: {
  link: NavItem;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
}) {
  const {
    link,
    onClick
  } = props;
  const className = `${props.className || ""} block py-2 hover:bg-gray-lighter px-2`;
  return <Link href={link.uri} onClick={onClick} className={className} data-sentry-element="Link" data-sentry-component="Item" data-sentry-source-file="AccountDropdown.tsx">
      {link.label}
    </Link>;
}
interface Props {
  menuIsInline?: boolean;
}
function AccountDropdown({
  menuIsInline
}: Props) {
  const [menuOpen, setMenuOpen] = useState<boolean>(menuIsInline ?? false);
  const appContext = useContext(MpAppContext);
  const {
    userStore
  } = appContext;
  const user = userStore && userStore.user;
  function avatarRow(user: Me) {
    const {
      photo
    } = user.profile;
    const userPhotoUrl = photo?.url;
    return <div className="flex my-2 px-2 text-gray-darkest" data-sentry-component="avatarRow" data-sentry-source-file="AccountDropdown.tsx">
        {userPhotoUrl && <img src={userPhotoUrl} alt="Profile and account menu" className="w-10 h-10 rounded-full mr-3" />}
        {!userPhotoUrl && <UserCircleIcon className="w-10 h-10 text-gray-dark" />}
        <div>
          <div>{user.account.name}</div>
          {user.account.status !== "BANNED" && <>
              <div className="text-gray-dark">{user.profile.title}</div>
              <Link href="/members/profile" className="a" onClick={itemClicked}>
                edit profile
              </Link>
            </>}
        </div>
      </div>;
  }
  function navItems() {
    const showAdmin = memberCanSeeAdmin(user?.account);
    return <>
        <Item link={{
        uri: "/members/account",
        label: "Account"
      }} onClick={itemClicked} data-sentry-element="Item" data-sentry-source-file="AccountDropdown.tsx" />
        {showAdmin && <Item link={{
        uri: "/_admin",
        label: "Admin"
      }} onClick={itemClicked} />}
        <div className="border-b my-1" />
        <Item link={{
        uri: "/logout",
        label: "Log Out"
      }} onClick={itemClicked} data-sentry-element="Item" data-sentry-source-file="AccountDropdown.tsx" />
      </>;
  }
  function itemClicked() {
    if (!menuIsInline) {
      setMenuOpen(false);
    }
  }
  if (!user) {
    return null;
  }
  const absoluteClass = menuIsInline ? "" : "absolute z-10 shadow right-0 w-64 rounded-sm mt-2 pb-1";
  return <div className="AccountDropdown relative text-sm" data-sentry-component="AccountDropdown" data-sentry-source-file="AccountDropdown.tsx">
      {!menuIsInline && <button className="w-10 flex items-center hover:brightness-110 transition" style={{
      position: "relative"
    }} onClick={() => !menuIsInline && setMenuOpen(!menuOpen)}>
          {user.profile?.photo?.url && <MemberPhoto photoUrl={user.profile.photo?.url} photoSize={40} />}
          {!user.profile?.photo?.url && <UserCircleIcon className="w-10 h-10 text-gray-dark" />}
        </button>}
      <div className={`${menuOpen ? "block" : "hidden"} ${absoluteClass} bg-white text-black border rounded-sm mt-2 pb-1`}>
        {avatarRow(user)}
        <div className="border-b my-1" />
        {navItems()}
      </div>
    </div>;
}
export default observer(AccountDropdown);