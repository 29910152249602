import AshMutationErrors from "components/alerts/AshMutationErrors";
import Flash from "components/Flash";
import { FormRow, RowLabel, TextInput } from "components/form";
import { useAshMutation } from "hooks/ash";
import { useLogin } from "lib/auth";
import { VERIFY_MEMBER_AUTH_CODE } from "lib/gf-api/member-api";
import { useUserStore } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Account, TinySelfMember } from "types/member";
interface Props {
  email: string;
  onAccessToken?: (accessToken: string, account: TinySelfMember) => void;
  onLoggedIn?: (account: Account, accessToken: string) => void;
  skipLogin?: boolean;
}
function AuthCodeForm(props: Props) {
  const {
    email,
    onAccessToken,
    skipLogin
  } = props;
  const [authCode, setAuthCode] = useState<string>("");
  const usingLogin = useLogin();
  const userStore = useUserStore();
  const codeRef = useRef<HTMLElement | null>(null);
  const [verifyMemberAuthCode, verifyMemberAuthCodeState] = useAshMutation<TinySelfMember>(VERIFY_MEMBER_AUTH_CODE);
  async function onAuthCodeChange(ev: ChangeEvent<HTMLInputElement>) {
    const newValue = ev.target.value;
    setAuthCode(newValue);
    if (newValue.length === 6) {
      const result = await verifyMemberAuthCode({
        variables: {
          input: {
            email,
            authCode: newValue
          }
        }
      }, "verifyMemberAuthCode");
      if (result.errors) {
        setAuthCode("");
      } else if (result.data) {
        const accessToken = result.metadata?.accessToken;
        const account = result.data;
        onAccessToken && accessToken && account && onAccessToken(accessToken, account);
        if (!skipLogin && accessToken) {
          usingLogin.login(accessToken);
          const member = await userStore.loadUser();
          if (member && props.onLoggedIn) {
            props.onLoggedIn(member.account, accessToken);
          }
        }
      }
    }
  }
  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.focus();
    }
  }, []);
  return <div data-sentry-component="AuthCodeForm" data-sentry-source-file="AuthCodeForm.tsx">
      <Flash data-sentry-element="Flash" data-sentry-source-file="AuthCodeForm.tsx">
        <span>
          We sent you an email to <b>{email}</b>
        </span>
      </Flash>
      <p className="p">
        Please look for the email we sent, and read the code from it.
      </p>
      <FormRow data-sentry-element="FormRow" data-sentry-source-file="AuthCodeForm.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="AuthCodeForm.tsx">Enter the code from the email:</RowLabel>
        <TextInput type="number" onChange={onAuthCodeChange} value={authCode} ref={codeRef} data-sentry-element="TextInput" data-sentry-source-file="AuthCodeForm.tsx" />
        <AshMutationErrors mutationInfo={verifyMemberAuthCodeState} data-sentry-element="AshMutationErrors" data-sentry-source-file="AuthCodeForm.tsx" />
      </FormRow>
    </div>;
}
export default observer(AuthCodeForm);