// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  children: React.ReactNode;
  className?: string;
}
export default function Legend(props: Props) {
  return <legend className={`px-2 text-gray-dark ${props?.className}`} data-sentry-component="Legend" data-sentry-source-file="Legend.tsx">
      {props.children}
    </legend>;
}