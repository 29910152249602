// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ProgressIndicator from "components/ProgressIndicator";
import React from "react";
interface Props {
  bold?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  inputRef?: React.RefCallback<HTMLButtonElement>;
  loading?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  paddingClass?: string;
  status?: string;
  savingLabel?: string;
  textClass?: string;
  type?: "button" | "submit";
}
const noClick = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};
const SubmitButton = function (props: Props) {
  const {
    id,
    loading,
    children,
    savingLabel
  } = props;
  let {
    disabled
  } = props;
  const type = props.type || "button";
  const onClick = loading ? noClick : props.onClick;
  const textClass = props.textClass || "text-white";
  const bgColorInactive = "bg-spp-violet";
  const bgColorActive = "bg-spp-violet-lighter";
  let bgColorHover = disabled ? "" : "hover:bg-spp-violet-lighter";
  let bgColor = props.status === "active" ? bgColorActive : bgColorInactive;
  let label;
  let extraClassName = "";
  if (loading) {
    disabled = true;
    label = savingLabel || "Loading…";
  } else {
    extraClassName = disabled ? "" : "hover:bg-spp-violet-lighter";
    label = children;
  }
  if (disabled) {
    extraClassName = "cursor-not-allowed";
    bgColor = "bg-gray";
    bgColorHover = "";
  }
  const bold = props.bold === true || props.bold === undefined;
  const boldClass = bold ? "font-semibold" : undefined;
  const paddingClass = props.paddingClass || "py-2 px-4";
  const baseClassName = `SubmitButton Button inline-flex flex-row gap-2 items-center justify-center w-fit transition ${bgColor} ${bgColorHover} ${textClass} ${boldClass} ${paddingClass} rounded-md shadow ${props.className || ""}`;
  const className = baseClassName + " " + extraClassName;
  return <button type={type} id={id} className={className} onClick={onClick} disabled={disabled} ref={props.inputRef}>
      {loading && <ProgressIndicator size={16} />}
      {label}
    </button>;
};
export default SubmitButton;