import Flash from "components/admin/ui/Flash";
import { MutationInfo } from "types/ash";
interface Props<T> {
  mutationInfo: MutationInfo<T>;
  message: string;
}
export default function AshMutationSuccess<T>({
  message,
  mutationInfo
}: Props<T>) {
  if (!mutationInfo) return null;
  const {
    serverError,
    loading,
    errors,
    data
  } = mutationInfo;
  if (!data || loading || serverError || errors && errors.length > 0) {
    return null;
  }
  return <Flash type="info" data-sentry-element="Flash" data-sentry-component="AshMutationSuccess" data-sentry-source-file="AshMutationSuccess.tsx">{message}</Flash>;
}