/**
 * Default <main> element for a page, that individual page components can use.
 */

import React, { useState } from "react";
import { ApiErrorState } from "types/api";
import GeneralApiError from "components/GeneralApiError";
import { observer } from "mobx-react-lite";
import { MemberStatus } from "types/member";
import { useMember } from "lib/gf-app-context";
import disallowedContent from "components/admin/members/statusMessaging/disallowedContent";
interface Props {
  apiError?: ApiErrorState;
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  memberStatusRequired?: MemberStatus;
  emailRequired?: boolean;
  sectionClass?: string;
  title?: React.ReactNode | string;
}
function MainContent(props: Props) {
  const {
    children,
    emailRequired,
    apiError,
    memberStatusRequired,
    fullWidth,
    title
  } = props;
  const [errorClosed, setErrorClosed]: [boolean, (value: boolean) => void] = useState((false as boolean));
  const member = useMember();
  let className = "MainContent";
  if (props.className) {
    className = className + " " + props.className;
  }
  className = className + " grow";
  let sectionClass = props.sectionClass || "w-full mx-auto";
  if (!fullWidth) {
    sectionClass = sectionClass + " max-w-[900px]";
  }
  let notAllowedContent = disallowedContent({
    memberStatusRequired,
    emailRequired,
    member
  });
  return <main className={className} data-sentry-component="MainContent" data-sentry-source-file="MainContent.tsx">
      <GeneralApiError apiError={errorClosed ? null : apiError} onClose={() => setErrorClosed(true)} data-sentry-element="GeneralApiError" data-sentry-source-file="MainContent.tsx" />
      <section className={sectionClass}>
        {title && <h1 className="font-bold text-3xl mb-4">{title}</h1>}
        {notAllowedContent || children}
      </section>
    </main>;
}
export default observer(MainContent);