import clsx from "clsx";
import ExpandArrow from "components/ui/ExpandArrow";
import { SortableProps } from "lib/sortableList2";
interface Props {
  expanded?: boolean;
  id?: string;
  label?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  innerClassName?: string;
  onCollapseOrExpand?: (expanding: boolean) => void;
  sortable?: SortableProps;
}
export default function FieldSet2(props: Props) {
  const {
    children,
    id,
    label,
    className,
    sortable
  } = props;
  let content = children;
  const expandable = typeof props.expanded === "boolean";
  if (expandable) {
    const className = clsx(props.expanded ? "block" : "hidden", props.innerClassName);
    content = <div className={className}>{children}</div>;
  }
  let finalLabel = label;
  if (expandable) {
    finalLabel = <div className="flex gap-4">
        {label}
        <ExpandArrow expanded={props.expanded ?? false} onChange={expanding => props.onCollapseOrExpand && props.onCollapseOrExpand(expanding)} />
      </div>;
  }
  return <fieldset className={`border rounded-md p-2 px-4 ${className || ""}`} id={id} {...sortable} data-sentry-component="FieldSet2" data-sentry-source-file="FieldSet2.tsx">
      <legend className="font-bold mb-2 px-2">{finalLabel}</legend>
      {content}
    </fieldset>;
}