/*!
 * v2 MarketingCard
 *
 * This version removes support for @craftjs, and adds support for WebComponents.
 */

import { ReactNode } from "react";
import Button from "./Button";
import clsx from "clsx";
interface Props {
  buttonClass?: string;
  children?: ReactNode;
  className?: string;
  imageAs?: "background" | "image";
  imageUri: string;
  index: number;
  heading: string;
  description?: ReactNode;
  buttonLabel?: string;
  buttonUrl?: string;
  order: string;
}
const MarketingCard = ({
  buttonClass,
  children,
  className: extraClass,
  imageAs,
  imageUri,
  index,
  heading,
  description,
  buttonLabel,
  buttonUrl,
  order
}: Props) => {
  return <div className={clsx("MarketingCard", order, index % 2 === 1 ? "odd" : "even", extraClass)} data-sentry-component="MarketingCard" data-sentry-source-file="MarketingCard.tsx">
      <div className="ImageColumn">
        <div className="ImageWrapper relative">
          <div className="ImageOverlay absolute inset-0">&nbsp;</div>
          {imageAs === "background" ? <div className="CardImage" style={{
          backgroundImage: `url(${imageUri})`
        }}>
              &nbsp;
            </div> : <img src={imageUri} className="CardImage" />}
        </div>
      </div>

      <div className="CardTextContent">
        <div className="CardHeading">{heading}</div>

        <div className="CardText">{children || description}</div>

        {buttonUrl && <>
            <div className="ButtonWrapper">
              <Button label={buttonLabel} href={buttonUrl} className={buttonClass} />
            </div>
          </>}
      </div>
    </div>;
};
export default MarketingCard;