// Copyright © 2017 Moxley Data Systems - All Rights Reserved

type Item = {
  id: string;
  name: string;
};
export default function ResultItem(props: {
  item: Item;
  currentId?: string | undefined;
  onClick?: (item: any) => void;
  mapItem?: (item: any) => React.ReactNode;
}) {
  const {
    item,
    currentId
  } = props;
  const highlightClass = "bg-spp-pink-highlight";
  const selectedClass = item.id === currentId && highlightClass;
  function onClick(e: any) {
    e.preventDefault();
    props.onClick && props.onClick(item);
  }
  function mapItem(item: Item) {
    if (props.mapItem) {
      return props.mapItem(item);
    }
    return item.name;
  }
  return <div className={`${selectedClass} hover:${highlightClass} cursor-pointer py-1 px-2`} onMouseDown={onClick} data-sentry-component="ResultItem" data-sentry-source-file="ResultItem.tsx">
      {mapItem(item)}
    </div>;
}