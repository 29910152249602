import { gmtOffset } from "lib/constants";
import { formatDate } from "lib/date";
import { buildClassName } from "lib/string";
interface Props {
  date: Date | string;
  textClassName?: string;
}
export default function TimezoneOffset({
  date,
  textClassName
}: Props) {
  return <span className={buildClassName(textClassName || "text-gray-dark text-xs")} data-sentry-component="TimezoneOffset" data-sentry-source-file="TiimezoneOffset.tsx">
      {formatDate(date, gmtOffset)}
    </span>;
}