interface Props {
  children: React.ReactNode;
  className?: string;
}
export default function Code(props: Props) {
  const {
    children,
    className
  } = props;
  return <code className={`bg-gray-lighter px-1 ${className || ""}`} data-sentry-component="Code" data-sentry-source-file="Code.tsx">
      {children}
    </code>;
}