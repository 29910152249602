import TrashIcon from "components/icon/TrashIcon";
interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  roundedClass?: string;
  size?: "small" | "medium";
  withIcon?: boolean;
}
export default function DeleteButton(props: Props) {
  let {
    className,
    children,
    disabled,
    onClick,
    size,
    withIcon
  } = props;
  let bgColor = "bg-red-dark";
  let borderColor = "border-red-dark";
  let hoverBgColor = "";
  let textColor = "text-white";
  if (disabled) {
    bgColor = "bg-gray";
    borderColor = "border-gray";
    textColor = "text-gray-dark";
  } else {
    hoverBgColor = "hover:bg-red-danger";
  }
  let padding = "py-1 px-3";
  let textSize = "";
  let trashStyle = {
    width: "20px",
    height: "20px"
  };
  let itemsGap = "gap-2";
  if (size === "small") {
    textSize = "text-sm";
    trashStyle = {
      width: "16px",
      height: "16px"
    };
    padding = "px-2";
    itemsGap = "gap-1";
  }
  const roundedClass = props.roundedClass || "rounded-md";
  className = `${className || ""} ${bgColor} ${hoverBgColor} ${textColor} ${textSize} border-2 ${borderColor} ${roundedClass} ${padding} inline-flex ${itemsGap} items-center`;
  return <button className={className} type="button" onClick={onClick} disabled={disabled} data-sentry-component="DeleteButton" data-sentry-source-file="DeleteButton.tsx">
      {withIcon && <TrashIcon className="fill-current" style={trashStyle} />}
      {children || "Delete"}
    </button>;
}