// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  className?: string;
  style?: any;
}
export default function YellowCircleIcon(props: Props) {
  const {
    className,
    style
  } = props;
  const otherProps = ({} as any);
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px" className={className} style={style} title="Yellow light" {...otherProps} data-sentry-element="svg" data-sentry-component="YellowCircleIcon" data-sentry-source-file="YellowCircleIcon.tsx">
      <defs data-sentry-element="defs" data-sentry-source-file="YellowCircleIcon.tsx">
        <linearGradient id="BTODxJqb_kNzKWyYy_kOFa" x1="-792" x2="-792" y1="6" y2="42" gradientTransform="translate(816)" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="YellowCircleIcon.tsx">
          <stop offset="0" stopColor="#fff082" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset="1" stopColor="#e5cd14" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
        </linearGradient>
        <radialGradient id="BTODxJqb_kNzKWyYy_kOFb" cx="-792" cy="24" r="18" gradientTransform="translate(816)" gradientUnits="userSpaceOnUse" data-sentry-element="radialGradient" data-sentry-source-file="YellowCircleIcon.tsx">
          <stop offset="0" stopColor="#ffef75" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".392" stopColor="#feee74" stopOpacity=".009" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".533" stopColor="#fdec70" stopOpacity=".044" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".633" stopColor="#f9e96a" stopOpacity=".103" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".715" stopColor="#f4e360" stopOpacity=".188" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".784" stopColor="#eedc54" stopOpacity=".299" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".846" stopColor="#e7d445" stopOpacity=".435" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".902" stopColor="#ddca33" stopOpacity=".598" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".952" stopColor="#d3bf1e" stopOpacity=".781" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset=".997" stopColor="#c8b207" stopOpacity=".987" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
          <stop offset="1" stopColor="#c7b106" data-sentry-element="stop" data-sentry-source-file="YellowCircleIcon.tsx" />
        </radialGradient>
      </defs>
      <circle cx="24" cy="24" r="18" fill="url(#BTODxJqb_kNzKWyYy_kOFa)" data-sentry-element="circle" data-sentry-source-file="YellowCircleIcon.tsx" />
      <circle cx="24" cy="24" r="18" fill="url(#BTODxJqb_kNzKWyYy_kOFb)" data-sentry-element="circle" data-sentry-source-file="YellowCircleIcon.tsx" />
    </svg>;
}