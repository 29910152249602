interface Props {
  children: React.ReactNode;
  className?: string;
}
export default function HelpText(props: Props) {
  const {
    children,
    className
  } = props;
  return <span className={`text-gray-darker ${className}`} data-sentry-component="HelpText" data-sentry-source-file="HelpText.tsx">{children}</span>;
}