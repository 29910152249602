import { observer } from "mobx-react-lite";
import EditableSection from "components/contentEditing/EditableSection";
import { usePosts } from "hooks/content";
import MarkdownContent from "components/MarkdownContent";
import { buildClassName } from "lib/string";
import Link from "next/link";
interface Props {
  className?: string;
  contentSlug?: string;
  parentTag?: string;
}
const PageQuote = (props: Props) => {
  const {
    className,
    contentSlug,
    parentTag
  } = props;
  const {
    getPostListBySlug: getPost
  } = usePosts();
  const content = contentSlug ? getPost(contentSlug) : undefined;
  const bgItem = content?.children?.find(c => c.baseType === "photo");
  const bgUrl = bgItem?.photo?.url;
  const buttonItem = content?.children?.find(c => c.baseType === "nav");
  return <EditableSection contentId={content?.id} navState={{
    baseType: "text",
    baseTypeEditMode: "edit",
    initialParams: {
      slug: contentSlug
    }
  }} className={buildClassName(parentTag, "PageQuote", className)} data-sentry-element="EditableSection" data-sentry-component="PageQuote" data-sentry-source-file="PageQuote.tsx">
      <img src={bgUrl} className="Image" />
      <div className="TextContent">
        <div className="Title">{content?.title}</div>
        <MarkdownContent className="Text" content={content?.content} data-sentry-element="MarkdownContent" data-sentry-source-file="PageQuote.tsx" />
        {buttonItem && <div className="ButtonWrapper">
            <Link href={buttonItem.uri || "/"} className="Button">
              {buttonItem.label}
            </Link>
          </div>}
      </div>
    </EditableSection>;
};
export default observer(PageQuote);