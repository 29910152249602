import clsx from "clsx";
import Spinner from "components/icon/Spinner";
import { SyntheticEvent } from "react";
import { useDebounce } from "use-debounce";
interface Props {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  disabledClass?: string;
  hoverClass?: string;
  loading?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  opacityClass?: string;
  paddingXClass?: string;
  size?: "medium" | "large";
  type?: "button" | "submit";
  widthClass?: string;
}
export default function ActionButton(props: Props) {
  const {
    children,
    className,
    onClick,
    type,
    widthClass
  } = props;
  const [loading] = useDebounce(props.loading ?? false, 500);
  const disabled = props.disabled || props.loading;
  const heightClass = props.type === "submit" || props.size === "large" ? "h-12" : "h-9";
  const paddingX = props.paddingXClass || (props.type === "submit" ? "px-6" : "px-5");
  const hoverClass = disabled ? undefined : props.hoverClass || "hover:saturate-150 hover:brightness-125";
  const bgColor = "bg-gradient-to-r from-new-green-500 to-new-green-600";
  const backgroundClass = disabled ? props.disabledClass || clsx(bgColor, "saturate-50 cursor-not-allowed") : bgColor;
  const opacityClass = disabled ? undefined : props.opacityClass;
  return <button type={type} disabled={disabled} className={clsx("flex items-center justify-center", widthClass, heightClass, paddingX, "rounded-full", backgroundClass, opacityClass, hoverClass, "text-white", "font-medium", "transition", className)} onClick={onClick} data-sentry-component="ActionButton" data-sentry-source-file="ActionButton.tsx">
      {loading && <Spinner className="h-5 w-5 mr-2" />}
      {children}
    </button>;
}