// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ContentItem } from "types/content";
import { useRef } from "react";
import ContentItemTypeIcon from "./ContentItemTypeIcon";
import GripCol from "./listItem/GripCol";
import DeleteCol from "./listItem/DeleteCol";
import LabelCol from "./listItem/LabelCol";
import ListItemWrapper from "./listItem/ListItemWrapper";
import StatusIcon from "./StatusIcon";
import { UseSortableReturn } from "types/sortable";
interface Props {
  sortable: UseSortableReturn;
  navItem: ContentItem;
  draggedNavItemRef?: React.MutableRefObject<ContentItem | null>;
  onClick?: (navItem: ContentItem) => void;
  onDelete?: (navItem: ContentItem) => void;
}
export default function NavListItem(props: Props) {
  const {
    navItem,
    sortable,
    onClick
  } = props;
  const disableClickRef = useRef((null as any));
  function onDelete(e: any) {
    e.preventDefault();
    e.stopPropagation();
    props.onDelete && props.onDelete(navItem);
  }
  return <ListItemWrapper id={`nav-item-${navItem.id}`} onClick={() => {
    if (disableClickRef.current) {
      return;
    }
    onClick && onClick(navItem);
  }} sortable={sortable} className="bg-white" data-sentry-element="ListItemWrapper" data-sentry-component="NavListItem" data-sentry-source-file="NavListItem.tsx">
      <GripCol data-sentry-element="GripCol" data-sentry-source-file="NavListItem.tsx" />
      <ContentItemTypeIcon contentItem={navItem} data-sentry-element="ContentItemTypeIcon" data-sentry-source-file="NavListItem.tsx" />
      <StatusIcon contentItem={navItem} data-sentry-element="StatusIcon" data-sentry-source-file="NavListItem.tsx" />
      <LabelCol data-sentry-element="LabelCol" data-sentry-source-file="NavListItem.tsx">
        <span className="inline-block" title={`ID: ${navItem.id}`}>
          {navItem.label}
        </span>
        {navItem.internalName && <span className="text-gray-dark inline-block truncate ml-2">
            - {navItem.internalName}
          </span>}
      </LabelCol>
      <DeleteCol onDelete={onDelete} data-sentry-element="DeleteCol" data-sentry-source-file="NavListItem.tsx" />
    </ListItemWrapper>;
}