// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  children: React.ReactNode;
  className?: string;
}
export default function Fieldset(props: Props) {
  const {
    children
  } = props;
  const className = `border border-solid border-gray rounded p-4 ${props.className || ""}`;
  return <fieldset className={className} data-sentry-component="Fieldset" data-sentry-source-file="Fieldset.tsx">{children}</fieldset>;
}