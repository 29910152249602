interface Props {
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
  className?: string;
}
export default function NeutralButton(props: Props) {
  let {
    className,
    children,
    onClick
  } = props;
  const bgColor = "white";
  const hoverBgColor = "gray-lighter";
  className = `${className} bg-${bgColor} hover:bg-${hoverBgColor} border-2 rounded-md py-1 px-3`;
  return <button className={className} type="button" onClick={onClick} data-sentry-component="NeutralButton" data-sentry-source-file="NeutralButton.tsx">
      {children || "Cancel"}
    </button>;
}