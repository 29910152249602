import Style from "lib/Style";
import SimpleModal from "components/SimpleModal";
interface Props {
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}
export default function FieldModalBasics(props: Props) {
  const {
    children,
    isOpen
  } = props;
  let modalStyle = Style.modalStyle;
  modalStyle = {
    ...modalStyle,
    content: {
      ...modalStyle.content
    }
  };
  return <SimpleModal isOpen={isOpen || false} modalStyle={modalStyle} onClose={props.onClose} className="gf-customer gf-area-members max-w-sm" data-sentry-element="SimpleModal" data-sentry-component="FieldModalBasics" data-sentry-source-file="FieldModalBasics.tsx">
      {children}
    </SimpleModal>;
}