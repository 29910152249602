import { ReactNode } from "react";
interface Props {
  children: ReactNode;
  className?: string;
}
export default function TabContainer({
  children,
  className
}: Props) {
  return <div className={`flex px-2 pt-2 gap-1 bg-gray-light rounded-t ${className}`} data-sentry-component="TabContainer" data-sentry-source-file="TabContainer.tsx">
      {children}
    </div>;
}