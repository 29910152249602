// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { TimeState } from "types/date";
import { pad } from "lib/string";
import { dateFormat, timeFormat, dayAndDate } from "lib/constants";
import { format } from "date-fns";
import DayDateTimeOffset from "components/util/DayDateTimeOffset";
import { parseDate } from "./gf-api/apiDecode";
export { pad };
export function parseMonth(monthString: string) {
  const match = monthString.match(/^(\d{4})-(\d{2})/);
  if (!match) throw new Error(`Bad format for month: ${monthString}`);
  const year = parseInt(match[1]);
  const month = parseInt(match[2]);
  return [year, month];
}
export function shiftMonth(monthString: string, offset: number): string {
  const [year, month] = parseMonth(monthString);
  let newYear = year;
  let newMonth = month + offset;
  if (newMonth < 1) {
    newMonth = 12;
    newYear = year - 1;
  } else if (newMonth > 12) {
    newMonth = 1;
    newYear = year + 1;
  }
  const newMonthString = `${newMonth}`.padStart(2, "0");
  return `${newYear}-${newMonthString}`;
}
export function timeStateToDate(date: Date, time: TimeState) {
  let hour = time.ampm === "PM" && time.hour < 12 ? time.hour + 12 : time.hour;
  hour = time.ampm === "AM" && time.hour === 12 ? 0 : hour;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, time.minute, 0);
}
export function calculateTime(date: Date): TimeState {
  let tdate = typeof date === "string" ? new Date(date) : date;
  let hour = tdate.getHours();
  if (hour === 0) hour = 12;
  if (hour > 12) hour -= 12;
  return {
    hour,
    minute: tdate.getMinutes(),
    ampm: tdate.getHours() >= 12 ? "PM" : "AM"
  };
}
export function beginningOfDay(date: Date) {
  return transformDate(date, {
    hour: 0,
    minute: 0,
    second: 0
  });
}
export function transformDate(date: Date, values: any) {
  return new Date(coalesce(values.year, date.getFullYear()), coalesce(values.month, date.getMonth()), coalesce(values.day, date.getDate()), coalesce(values.hour, date.getHours()), coalesce(values.minute, date.getMinutes()), coalesce(values.second, date.getSeconds()));
}
function coalesce(a: any, b: any) {
  if (a === null || a === undefined) {
    return b;
  } else {
    return a;
  }
}
export function timeAgo(date: Date) {
  const now = new Date();
  const seconds = (now.valueOf() - date.valueOf()) / 1000;
  if (seconds >= 0) {
    const text = englishRelativeTime(seconds);
    return `${text} ago`;
  } else {
    const text = englishRelativeTime(-seconds);
    return `in ${text}`;
  }
}
export function englishRelativeTime(seconds: number) {
  let minutes = seconds / 60;
  let hours = minutes / 60;
  let days = hours / 24;
  let weeks = days / 7;
  let years = days / 365.25;
  let months = years * 12;
  if (years >= 1) {
    years = Math.round(years);
    const unit = years > 1 ? "years" : "year";
    return `${years} ${unit}`;
  } else if (months >= 1) {
    months = Math.round(months);
    const unit = months > 1 ? "months" : "month";
    return `${months} ${unit}`;
  } else if (weeks >= 1) {
    weeks = Math.round(weeks);
    const unit = weeks > 1 ? "weeks" : "week";
    return `${weeks} ${unit}`;
  } else if (days >= 1) {
    days = Math.round(days);
    const unit = days > 1 ? "days" : "day";
    return `${days} ${unit}`;
  } else if (hours >= 1) {
    hours = Math.round(hours);
    const unit = hours > 1 ? "hours" : "hour";
    return `${hours} ${unit}`;
  } else if (minutes >= 1) {
    minutes = Math.round(minutes);
    const unit = minutes > 1 ? "minutes" : "minute";
    return `${minutes} ${unit}`;
  } else if (seconds >= 0) {
    const unit = seconds > 1 ? "seconds" : "second";
    return `${seconds} ${unit}`;
  } else {
    return "now";
  }
}
export function formatDate(date: Date | string | null | undefined, formatString?: string) {
  formatString = formatString || dateFormat;
  if (typeof date === "string") {
    if (date.match(/\d{2}:\d{2}:\d{2}$/)) {
      date = date + "Z";
    }
    date = parseDate(date);
  }
  if (!date) {
    return null;
  }
  return date && format(date, formatString);
}
export function formatDateTime(date: Date) {
  return <DayDateTimeOffset dateTime={date} data-sentry-element="DayDateTimeOffset" data-sentry-component="formatDateTime" data-sentry-source-file="date.tsx" />;
}
export function formatTime(date: Date | string) {
  return formatDate(date, timeFormat);
}
export function formattedGraduatedDate(date: Date) {
  const beginningOfDay = new Date();
  beginningOfDay.setHours(0);
  beginningOfDay.setMinutes(0);
  beginningOfDay.setSeconds(0);
  const day = 60 * 60 * 24;
  const sixDays = day * 6;
  const now = new Date();
  const sixDaysAgo = new Date(now.valueOf() - sixDays * 1000);
  const year = day * 365;
  const yearAgo = new Date(now.valueOf() - year * 1000);
  if (date.valueOf() >= beginningOfDay.valueOf()) {
    // Show time
    return formatTime(date);
  } else if (date.valueOf() >= sixDaysAgo.valueOf()) {
    // Show day of week and time
    return formatDate(date, `EEEE ${timeFormat}`);
  } else if (date.valueOf() >= yearAgo.valueOf()) {
    // Show month, day, and time
    return <>
        <span className="mr-2"> {formatDate(date, dayAndDate)}</span>{" "}
        {formatDate(date, timeFormat)}
      </>;
  } else {
    // Show date with year, and time
    return formatDate(date, `${dateFormat} ${timeFormat}`);
  }
}
export function parseDateTime({
  date: dateString,
  time
}: {
  date: string;
  time: string;
}): Date {
  const date = new Date(dateString);
  const [hour, minute] = time.split(":");
  date.setHours(parseInt(hour));
  date.setMinutes(parseInt(minute));
  date.setSeconds(0);
  return date;
}