// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  children: React.ReactNode;
}
export default function TitleSubHeading(props: Props) {
  const {
    children
  } = props;
  return <div className="text-gray-dark ml-2 whitespace-nowrap whitespace-nowrap truncate" data-sentry-component="TitleSubHeading" data-sentry-source-file="TitleSubHeading.tsx">
      {children}
    </div>;
}