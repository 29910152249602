import AccountDropdown from "components/nav/AccountDropdown";
import { useMemberJwt } from "lib/gf-app-context";
import ItemLink from "./ItemLink";
interface Props {
  menuIsInline?: boolean;
}
export const UserMenuButton = ({
  menuIsInline: isOpen
}: Props) => {
  const memberJwt = useMemberJwt();
  const isLoggedIn = !!memberJwt;
  if (isLoggedIn) {
    return <AccountDropdown menuIsInline={isOpen} />;
  }
  return <ItemLink href="/login" data-sentry-element="ItemLink" data-sentry-component="UserMenuButton" data-sentry-source-file="UserMenuButton.tsx">Login</ItemLink>;
};