import React, { ReactElement } from "react";
function renderLinks(text: string): ReactElement {
  const match = text.match(/^(.*)\b(https?:\/\/\S+[^\s.])(.*)$/);
  if (match) {
    const leadingText = match[1];
    const url = match[2];
    const rest = match[3];
    return <>
        {leadingText}
        <a className="a" href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
        {renderLinks(rest)}
      </>;
  } else {
    return <>{text}</>;
  }
}
type AnnotatedLine = ["p", string | ["br", string][]] | ["br", string] | [null, string];
export default function TextToHtml({
  text
}: {
  text: string;
}) {
  const items = text.split(/\r?\n/);
  const annotatedItems: AnnotatedLine[] = [];
  for (let i = 0; i < items.length; i++) {
    const line = items[i];
    if (line === "") {
      annotatedItems.push(["br", ""]);
    } else {
      let lines: string[] = [line];
      let nextLine = "";
      do {
        i++;
        nextLine = items[i];
        if (nextLine) {
          lines = [...lines, nextLine];
        }
      } while (nextLine);
      const subItems: ["br", string][] = lines.map(line => ["br", line]);
      annotatedItems.push(["p", subItems]);
    }
  }
  const children = annotatedItems.map((annotatedLine, index) => <React.Fragment key={index}>
      {renderAnnotatedLine(annotatedLine)}
    </React.Fragment>);
  return <>{children}</>;
}
function renderAnnotatedLine([tag, lineOrLines]: AnnotatedLine) {
  let formattedLine: string | ReactElement = <></>;
  if (tag === "p" && Array.isArray(lineOrLines)) {
    lineOrLines.forEach(line => {
      const lineEl = renderAnnotatedLine(line);
      formattedLine = <>
          {formattedLine}
          {lineEl}
        </>;
    });
  } else {
    if (typeof lineOrLines === "string") {
      formattedLine = renderLinks(lineOrLines) || <>&nbsp;</>;
    }
  }
  if (tag === "br") {
    return <>
        {formattedLine}
        <br />
      </>;
  } else if (tag === "p") {
    return <p>{formattedLine}</p>;
  } else {
    return <>{formattedLine}</>;
  }
}