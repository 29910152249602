import clsx from "clsx";
import { formFieldStyle } from "lib/Style";
import { useThemeInfo } from "lib/theme";
import React from "react";
interface Props {
  autoComplete?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  inputRef?: any;
  maxLength?: number;
  max?: number | string;
  min?: number | string;
  name?: string;
  onBlur?: (event: any) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onKeyDown?: (event: any) => void;
  pattern?: string;
  placeholder?: string;
  readOnly?: boolean;
  register?: any;
  required?: boolean;
  step?: number | string;
  style?: any;
  type?: string;
  value?: string | null;
}
const TextInput = React.forwardRef((props: Props, ref: any) => {
  let {
    inputRef,
    className,
    register,
    type,
    value,
    ...additionalProps
  } = props;
  const themeInfo = useThemeInfo();
  const themeArea = themeInfo.themeArea;
  if (register) {
    additionalProps = {
      ...additionalProps,
      ...register
    };
  }
  if (value !== undefined) {
    additionalProps = ({
      ...additionalProps,
      value: value || ""
    } as any);
  }
  if (inputRef !== undefined) {
    additionalProps = ({
      ...additionalProps,
      ref: inputRef
    } as any);
  } else if (ref) {
    additionalProps = ({
      ...additionalProps,
      ref
    } as any);
  }
  const themeClass = formFieldStyle(themeArea);
  return <input className={clsx(themeClass, "w-full", className)} type={type || "text"} {...additionalProps} />;
});
export default TextInput;