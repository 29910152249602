import ItemSearch from "components/ItemSearch";
import SimpleModal from "components/SimpleModal";
import { useApiCallParams, useMemberJwt } from "lib/gf-app-context";
import { listContentItems2 } from "lib/gf-api/content-api";
import { useState } from "react";
import { ContentBaseType, ContentItem } from "types/content";
import TextItemResultItem from "./TextItemResultItem";
interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onItemSelected?: (item: ContentItem) => void;
  prompt?: string;
  queryParams?: any;
}
export default function SelectTextItem(props: Props) {
  const {
    isOpen,
    onClose,
    prompt,
    queryParams
  } = props;
  const jwt = useMemberJwt();
  if (!jwt) return null;
  const apiProps = useApiCallParams();
  const [items, setItems] = useState<null | ContentItem[]>(null);
  function onSearch(queryString: string) {
    const params = {
      baseType: ("text" as ContentBaseType),
      queryString,
      ...(queryParams || {})
    };
    listContentItems2(apiProps, params).then(result => {
      if (!result.error) {
        setItems((result.data as ContentItem[]));
      }
    });
  }
  function onItemSelected(navItem: ContentItem) {
    props.onItemSelected && props.onItemSelected(navItem);
    onClose && onClose();
  }
  return <SimpleModal isOpen={isOpen || false} onClose={onClose} contentStyle={{
    minHeight: "400px"
  }} themeArea="admin" data-sentry-element="SimpleModal" data-sentry-component="SelectTextItem" data-sentry-source-file="SelectTextItem.tsx">
      <div>{prompt || "Choose existing child"}</div>
      <ItemSearch focusOnLoad onSearch={onSearch} onItemSelected={onItemSelected} items={items} mapResultItem={item => <TextItemResultItem contentItem={item} />} data-sentry-element="ItemSearch" data-sentry-source-file="SelectTextItem.tsx" />
    </SimpleModal>;
}