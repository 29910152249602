import { NeutralButton, DeleteButton } from "components/ui";
import SimpleModal from "components/SimpleModal";
import ApiErrors from "components/ApiErrors";
import { ApiResponse } from "types/api";
import { ThemeArea } from "types/theme";
interface Props {
  deleting?: boolean;
  deleteLabel?: string;
  deletionResponse?: ApiResponse<any>;
  onClose?: () => void;
  onDelete?: () => void;
  prompt?: string;
  themeArea: ThemeArea;
}
export default function ConfirmDelete(props: Props) {
  const {
    deleting,
    deletionResponse,
    onClose,
    onDelete,
    prompt
  } = props;
  return <SimpleModal isOpen={Boolean(deleting)} onClose={onClose} themeArea={props.themeArea ?? "admin"} data-sentry-element="SimpleModal" data-sentry-component="ConfirmDelete" data-sentry-source-file="ConfirmDelete.tsx">
      <p className="p">
        {prompt || "Are you sure you want to delete this item?"}
      </p>

      <ApiErrors error={deletionResponse} data-sentry-element="ApiErrors" data-sentry-source-file="ConfirmDelete.tsx" />

      <div className="flex gap-4 justify-center mt-8">
        <NeutralButton onClick={onClose} data-sentry-element="NeutralButton" data-sentry-source-file="ConfirmDelete.tsx">Cancel</NeutralButton>
        <DeleteButton onClick={onDelete} withIcon data-sentry-element="DeleteButton" data-sentry-source-file="ConfirmDelete.tsx">
          {props.deleteLabel || "Delete"}
        </DeleteButton>
      </div>
    </SimpleModal>;
}