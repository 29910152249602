import BackButtonWithLabel from "components/ui/BackButtonWithLabel";
import { useAshMutation } from "hooks/ash";
import { useLogin } from "lib/auth";
import { CONFIRM_ACCOUNT, SIGNUP_WITH_AUTH } from "lib/gf-api/member-api";
import { createSession } from "lib/gf-api/session";
import { useApiCallParams } from "lib/gf-app-context";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { TinySelfMember } from "types/member";
import { AccountConfirmationForm } from "types/account";
import EmailPrompt from "./EmailPrompt";
import { LoginStatus } from "types/auth";
import PasswordPrompt from "./PasswordPrompt";
import AuthCodeFlow from "./AuthCodeFlow";
import { observer } from "mobx-react-lite";
import Spinner from "components/icon/Spinner";
import CheckboxWithLabel from "components/form/CheckBoxWithLabel";
import Button from "components/Button";
import { parseBoolean } from "lib/string";
import Style from "lib/Style";
import AshMutationResult from "components/alerts/AshMutationResult";
import NameField from "components/form/NameField";
interface Login {
  email: string;
  password: string;
}
type LoginScreen = "email" | "password" | "sentCode" | "redirecting" | "activate-account";

// Login form used in pop-up modal
function LoginForm() {
  const [passwordLoginStatus, setPasswordLoginStatus] = useState<LoginStatus>("notLoggedIn");
  const hookForm = useForm<Login>({
    defaultValues: {
      email: "",
      password: ""
    }
  });
  const {
    watch
  } = hookForm;
  const {
    login
  } = useLogin();
  const apiCall = useApiCallParams();
  const [error, setError] = useState<string>("");
  const router = useRouter();
  const ret = (router.query.ret as string);
  const [screen, setScreen] = useState<LoginScreen>("email");
  const [signupWithAuth, signupWithAuthState] = useAshMutation(SIGNUP_WITH_AUTH);
  const [codeSent, setCodeSent] = useState(false);
  const accessTokenRef = useRef("");
  const accountRef = useRef<TinySelfMember | null>(null);
  const accountConfirmationForm = useForm<AccountConfirmationForm>({
    defaultValues: {
      emailOptIn: true,
      name: ""
    }
  });
  const [apiConfirmAccount, confirmAccountState] = useAshMutation(CONFIRM_ACCOUNT);
  async function onPasswordSubmit(formValues1: any) {
    setPasswordLoginStatus("loggingIn");
    const formValues = (formValues1 as Login);
    try {
      const r = await createSession(apiCall, formValues);
      if (r.error) {
        console.warn("Failed login:", r);
        if (r.status === 401 || r.status === 403) {
          setPasswordLoginStatus("failedLogin");
        } else {
          setTechnicalDifficulties();
        }
      } else {
        // When the API hostname matches the frontend hostname, we
        // can simply use the cookie the API set for us. If the hostnames
        // differ, we must set another cookie having the frontend hostname.

        const {
          accessToken,
          account
        } = r.data;
        onLoggedIn(accessToken, account);
      }
    } catch (error) {
      setTechnicalDifficulties();
    }
  }
  function onLoggedIn(accessToken: string, account: TinySelfMember) {
    login(accessToken);
    setScreen("redirecting");
    const anyStatus = ["/members/subscriptions", "/members/account", "/members/profile", "/events"];
    if (anyStatus.includes(router.asPath)) {
      window.location.reload();
    } else if (router.asPath.startsWith("/members/dues") && ["INACTIVE", "ACTIVE"].includes(account.status)) {
      window.location.reload();
    } else if (router.asPath.startsWith("/members") && account.status === "ACTIVE") {
      window.location.reload();
    } else {
      let defaultReturnUri = "/events";
      if (account.status === "INACTIVE") {
        defaultReturnUri = "/members/dues";
      }
      const returnUri = ret || defaultReturnUri;
      window.location.assign(returnUri);
    }
  }
  function onAuthCodeAccessToken(accessToken: string, account: TinySelfMember) {
    if (account.accountConfirmed) {
      onLoggedIn(accessToken, account);
    } else {
      accessTokenRef.current = accessToken;
      accountRef.current = account;
      accountConfirmationForm.setValue("name", account.name);
      setScreen("activate-account");
    }
  }
  async function onAuthCodeSelection() {
    const input = {
      email: watch("email"),
      source: "LOGIN"
    };
    const result = await signupWithAuth({
      variables: {
        input
      }
    }, "signupWithAuth");
    if (!result.hasError) {
      setCodeSent(true);
    }
  }
  function setTechnicalDifficulties() {
    setError("We're encountering technical difficulties. Please try again later.");
    setPasswordLoginStatus("serverError");
  }
  function onBackClick() {
    setScreen("email");
    setCodeSent(false);
    setError("");
    setPasswordLoginStatus("notLoggedIn");
  }
  async function onConfirmAccountSubmit(data: AccountConfirmationForm) {
    const emailOptIn = parseBoolean(data.emailOptIn);
    let input: Record<string, any> = {
      emailOptIn,
      name: data.name
    };
    const accessToken = accessTokenRef.current;
    if (!accessToken) throw new Error("missing accessToken");
    const resp = await apiConfirmAccount({
      variables: {
        input
      },
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    }, "confirmAccount");
    if (!resp.hasError && accountRef.current) {
      onLoggedIn(accessToken, accountRef.current);
    }
  }
  function onCancelActivateAccount() {
    setScreen("email");
  }
  return <div data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      <div className="login-form-content flex flex-col gap-4 p-4">
        <h2 className="h2 !m-0 text-center">Account Login</h2>
        {screen === "email" && <EmailPrompt form={hookForm} onSubmit={() => setScreen("password")} />}

        {screen === "password" && <div className="flex flex-col gap-y-4">
            <BackButtonWithLabel label="Back" onClick={onBackClick} />
            {!codeSent && <>
                <PasswordPrompt form={hookForm} error={error} loginStatus={passwordLoginStatus} onSubmit={onPasswordSubmit} />
                <div className="text-center font-bold text-lg">OR</div>
              </>}
            <div className="rounded-xl bg-white p-4">
              <p className="text-center pb-4">
                Send login code to {watch("email")}
              </p>
              <AuthCodeFlow codeSent={codeSent} form={hookForm} onAuthCodeSelection={onAuthCodeSelection} onAccessToken={onAuthCodeAccessToken} signupWithAuthState={signupWithAuthState} />
            </div>
          </div>}

        {screen === "activate-account" && <form onSubmit={accountConfirmationForm.handleSubmit(onConfirmAccountSubmit)} className={Style.formClass}>
            <div>
              <h2 className="mb-4">Activate account</h2>
              <p>
                No user account was found for {watch("email")}. Do you want to
                activate this account?
              </p>
            </div>
            <div>
              <label htmlFor="name">Your name</label>
              <NameField form={accountConfirmationForm} focusOnLoad />
            </div>

            <CheckboxWithLabel label="Email me when there are new events and announcements" register={accountConfirmationForm.register("emailOptIn")} />
            <Button type="submit">Yes, activate this account</Button>

            <AshMutationResult mutationInfo={confirmAccountState} successMessage="Account activated" />

            <div className="flex flex-col items-center">
              <Button type="button" onClick={onCancelActivateAccount}>
                Nope, nevermind
              </Button>
            </div>
          </form>}

        {screen === "redirecting" && <div className="flex flex-col items-center">
            <Spinner className="w-8 h-8" />
          </div>}
      </div>
    </div>;
}
export default observer(LoginForm);