import { scriptify } from "lib/content";
import { useRef } from "react";
export default function useScriptableContent() {
  const containerLoaded = useRef<boolean>(false);
  function onExternalContentLoad(el: any) {
    if (containerLoaded.current) return;
    containerLoaded.current = true;
    scriptify(el);
  }
  return {
    onExternalContentLoad
  };
}