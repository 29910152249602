import clsx from "clsx";
import GroupFlowWideLogo from "components/icon/GroupFlowWideLogo";
import { useGroupConfig } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
interface Props {
  className?: string;
  parentTag?: string;
}
const GroupFlowTag = ({
  className,
  parentTag
}: Props) => {
  const group = useGroupConfig();
  return <a className={clsx(parentTag, "GroupFlowTag", className)} href={`https://www.groupflow.app/?utm_source=group-site&utm_keyword=${group.slug}`} data-sentry-component="GroupFlowTag" data-sentry-source-file="GroupFlowTag.tsx">
      <div className="PoweredBy">Powered by</div>

      <span className="LogoWrapper">
        <GroupFlowWideLogo className="Logo" data-sentry-element="GroupFlowWideLogo" data-sentry-source-file="GroupFlowTag.tsx" />
      </span>
    </a>;
};
export default observer(GroupFlowTag);