// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { usePageHeader } from "lib/content";
import Head from "next/head";
export default function WindowTitle(props: {
  title?: string;
}) {
  const {
    title
  } = props;
  const {
    pageTitle
  } = usePageHeader();
  return <Head data-sentry-element="Head" data-sentry-component="WindowTitle" data-sentry-source-file="WindowTitle.tsx">
      <title>{pageTitle(title)}</title>
    </Head>;
}