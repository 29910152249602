import { UserCircleIcon } from "@heroicons/react/24/outline";
import { buildClassName } from "lib/string";
interface Props {
  alt?: string;
  name?: string;
  photoUrl?: string | null;
  // Example size: 40
  photoSize?: number;
  className?: string;
  domRef?: React.RefObject<HTMLImageElement>;
  style?: any;
}
export default function MemberPhoto(props: Props) {
  const {
    className,
    domRef
  } = props;
  let {
    photoUrl
  } = props;
  let style: any;
  if (props.photoSize) {
    const photoSize = props.photoSize;
    style = {
      ...{
        minWidth: `${photoSize}px`,
        maxWidth: `${photoSize}px`,
        width: `${photoSize}px`,
        height: `${photoSize}px`
      },
      ...(props.style || {})
    };
  } else {
    style = props.style;
  }
  let alt = props.alt;
  if (props.name) {
    alt = `Member photo of ${props.name}`;
  }
  if (!photoUrl) {
    return <UserCircleIcon className={buildClassName(className)} style={style} />;
  }
  return <img className={buildClassName(className, "rounded-full object-cover md:block")} ref={domRef} src={photoUrl} style={style} alt={alt || "Member photo"} data-sentry-component="MemberPhoto" data-sentry-source-file="MemberPhoto.tsx" />;
}