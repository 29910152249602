/*!
 * Form fields for TextEdit.
 */

import { ErrorMessage, FieldSet2, FormRow, RowLabel, TextInput } from "components/form";
import RequiredFieldAsterisk from "components/form/RequiredFieldAsterisk";
import Code from "components/text/Code";
import HelpText from "components/text/HelpText";
import DayDateTimeOffset from "components/util/DayDateTimeOffset";
import { ALLOWED_CONTENT_URI_PREFIXES, isPresetTextItem } from "lib/content";
import Style from "lib/Style";
import Link from "next/link";
import React, { Fragment } from "react";
import { UseFormReturn } from "react-hook-form";
import { ContentItemExtended, TextEditForm } from "types/content";
import ContentTextArea from "../ContentTextArea";
import PhotoUploadField from "../PhotoUploadField";
interface Props {
  contentItem: ContentItemExtended | undefined;
  hookForm: UseFormReturn<TextEditForm, any>;
}
export default function FormFields(props: Props) {
  const {
    contentItem,
    hookForm
  } = props;
  const {
    register,
    formState,
    setValue,
    watch
  } = hookForm;
  const {
    errors
  } = formState;
  const subType = watch("subType");
  function onPhotoUploaded({
    photoId,
    url
  }: {
    photoId: string;
    url: string;
  }) {
    setValue("photoItem.photoId", photoId);
    setValue("photoUrl", url);
  }
  function onRemovePhoto() {
    setValue("photoItem.id", null);
    setValue("photoItem.photoId", null);
    setValue("photoItem.content", "");
    setValue("photoUrl", null);
  }
  function photoValues() {
    return {
      id: watch("photoItem.id"),
      photoId: watch("photoItem.photoId"),
      content: watch("photoItem.content"),
      url: watch("photoUrl")
    };
  }
  return <>
      {contentItem?.publishedAt && <FormRow>
          <RowLabel>Last Modified At:</RowLabel>
          <DayDateTimeOffset dateTime={contentItem.publishedAt} />
        </FormRow>}
      {contentItem?.author && <FormRow>
          <RowLabel>Author</RowLabel>
          <div>
            <Link href={`/members/${contentItem.author.id}`} className="a">
              {contentItem.author.name}
            </Link>
          </div>
        </FormRow>}

      {contentItem && isPresetTextItem(contentItem) && <div className="text-gray-darker">
          This is a preset text item. Preset text items cannot be deleted.
        </div>}

      {subType === "post" && !contentItem && <FormRow>
          {watch("plurality") === "list" ? <>
              <RowLabel>Adding a Post Container</RowLabel>
              <HelpText>
                Start by defining the container that will contain a list of
                posts on the page. After defining the container, posts can be
                added in the next step.
              </HelpText>
            </> : null}
        </FormRow>}

      <FormRow data-sentry-element="FormRow" data-sentry-source-file="FormFields.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="FormFields.tsx">
          Title
          <RequiredFieldAsterisk data-sentry-element="RequiredFieldAsterisk" data-sentry-source-file="FormFields.tsx" />
        </RowLabel>
        {subType === "page" && <HelpText>
            The page title becomes part of the window or tab title. It is not
            displayed on the page.
          </HelpText>}
        <TextInput {...register("title")} data-sentry-element="TextInput" data-sentry-source-file="FormFields.tsx" />
      </FormRow>

      <FormRow data-sentry-element="FormRow" data-sentry-source-file="FormFields.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="FormFields.tsx">Internal name</RowLabel>
        <HelpText data-sentry-element="HelpText" data-sentry-source-file="FormFields.tsx">
          This provides additional information about the page, for easier
          searching.
        </HelpText>
        <TextInput {...register("internalName")} data-sentry-element="TextInput" data-sentry-source-file="FormFields.tsx" />
      </FormRow>

      {subType === "post" && <>
          {watch("plurality") === "item" && <FormRow>
              <RowLabel>Photo</RowLabel>
              <PhotoUploadField photoValues={photoValues()} captionRegister={register("photoItem.content")} photoIdRegister={register("photoItem.photoId")} onPhotoUploaded={onPhotoUploaded} onRemovePhoto={onRemovePhoto} />
            </FormRow>}
        </>}

      {subType === "page" && <FormRow>
          <RowLabel>URI path</RowLabel>
          {contentItem && isPresetTextItem(contentItem) ? <>
              <p className="p text-gray-darker">
                This is a preset page. Its URI path cannot be changed.
              </p>
              URI: <Code>{contentItem.uri}</Code>
            </> : <>
              <HelpText>
                Enter a local path for the page. Local paths start with{" "}
                <Code>/</Code>. The URI path must start with either{" "}
                {ALLOWED_CONTENT_URI_PREFIXES.map((prefix, i) => <Fragment key={i}>
                    {i > 0 && i < ALLOWED_CONTENT_URI_PREFIXES.length ? " or " : ""}
                    <Code>{prefix + "/"}</Code>
                  </Fragment>)}
              </HelpText>
              <TextInput register={register("uri")} />
            </>}
        </FormRow>}

      {subType === "post" && watch("plurality") === "item" && <FieldSet2 label="Button" className={Style.formClass}>
          <div className="min-w-[15em]">
            <RowLabel>Label</RowLabel>
            <TextInput register={register("buttonItem.label")} />
          </div>
          <div className="min-w-[15em]">
            <RowLabel>URI path</RowLabel>
            <TextInput register={register("buttonItem.uri")} />
          </div>
        </FieldSet2>}

      <FormRow data-sentry-element="FormRow" data-sentry-source-file="FormFields.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="FormFields.tsx">Tag or Slug</RowLabel>
        <HelpText data-sentry-element="HelpText" data-sentry-source-file="FormFields.tsx">
          This is a unique identifier of this content item that is used as a
          reference from content components that depend on it. Only letters,
          numeric digits, lower-case characters and the minus sign (
          <code>-</code>) may be used. The slug should start with a letter.
        </HelpText>
        <TextInput register={register("slug", {
        pattern: /^[a-z][a-z0-9-]*$/
      })} data-sentry-element="TextInput" data-sentry-source-file="FormFields.tsx" />
        <ErrorMessage name="slug" errors={formState.errors} messages={{
        pattern: "invalid character pattern"
      }} data-sentry-element="ErrorMessage" data-sentry-source-file="FormFields.tsx" />
      </FormRow>

      {watch("plurality") === "item" && <FormRow>
          <RowLabel>Text content</RowLabel>
          <HelpText>
            Formatting can be added to the text using the{" "}
            <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noopener noreferrer" className="a">
              Markdown language
            </a>
            . HTML can be used for additional control.
          </HelpText>
          <ContentTextArea register={register("content")} hookForm={hookForm} />
          {errors.content && <div className="text-red-danger">
              Error: {errors.content.message}
            </div>}
        </FormRow>}

      <FormRow data-sentry-element="FormRow" data-sentry-source-file="FormFields.tsx">
        <div>
          <input type="checkbox" {...register("isPublished")} id="isPublished" />
          <label htmlFor="isPublished" className="flex-grow ml-2">
            Make published
          </label>
        </div>
      </FormRow>
    </>;
}