import clsx from "clsx";
import Link from "next/link";
interface Props {
  className?: string;
  href?: string;
  label?: string;
  parentTag?: string;
}
export default function Button(props: Props) {
  const {
    className,
    href,
    label,
    parentTag
  } = props;
  return <Link href={href || "/"} className={clsx(parentTag, "Button", className)} data-sentry-element="Link" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      <span className="pre-content" />
      {label}
      <span className="post-content" />
    </Link>;
}