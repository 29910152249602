import SimpleModal from "components/SimpleModal";
import { useGroupConfig } from "lib/gf-app-context";
import { useState } from "react";
const featureFlag = false;
export default function SupportChat() {
  const [isOpen, setIsOpen] = useState(false);
  const group = useGroupConfig();
  if (!group.trialEndsAt || !featureFlag) {
    return null;
  }
  return <div data-sentry-component="SupportChat" data-sentry-source-file="SupportChat.tsx">
      <button className="SupportChat flex justify-center gap-1 w-full m-1 border rounded-full font-montserrat px-4 py-1" onClick={() => setIsOpen(true)}>
        Help
      </button>
      <SimpleModal isOpen={isOpen} onClose={() => setIsOpen(false)} data-sentry-element="SimpleModal" data-sentry-source-file="SupportChat.tsx">
        <div className="flex flex-col gap-8">
          <p>
            This is a chat support system. You can ask any questions you have
            and we will try to help you as soon as possible.
          </p>
          <div className="flex justify-center align-center gap-4">
            <button onClick={() => setIsOpen(false)} className="SupportChatButton">
              Close
            </button>
          </div>
        </div>
      </SimpleModal>
    </div>;
}