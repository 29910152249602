// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ResultItem from "./ResultItem";
interface Props {
  items: {
    id: string;
    name: string;
  }[] | undefined;
  currentId: string | undefined;
  onItemClick?: (item: any) => void;
  mapItem?: (item: any) => React.ReactNode;
  childrenAfter?: React.ReactNode;
}
export default function ResultsDropDown(props: Props) {
  const {
    items,
    currentId,
    onItemClick,
    mapItem,
    childrenAfter
  } = props;
  if (!items) return null;
  let results = <div className="py-1 px-2">(no results)</div>;
  if (items.length > 0) {
    results = <>
        {items.map((item, index) => <ResultItem key={index} item={item} currentId={currentId} onClick={() => onItemClick && onItemClick(item)} mapItem={mapItem} />)}
      </>;
  }
  return <div className="absolute bg-white py-2 border w-full z-10 max-h-80 overflow-x-auto overflow-y-scroll" data-sentry-component="ResultsDropDown" data-sentry-source-file="ResultsDropDown.tsx">
      {results}
      {childrenAfter}
    </div>;
}