// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React from "react";
import Style from "lib/Style";
import RequiredFieldAsterisk from "./RequiredFieldAsterisk";
import clsx from "clsx";
export interface RadioProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  idPrefix?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  label: string | React.ReactNode;
  name?: string;
  onDomChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onChange?: (value: string) => void;
  onClick?: (value: string) => void;
  required?: boolean;
  register?: any;
  value: string | number;
}
const RadioWithLabel = React.forwardRef((props: RadioProps, ref) => {
  let {
    label,
    className,
    idPrefix,
    inputRef,
    onClick,
    onChange,
    onDomChange,
    register,
    required,
    ...inputProps
  } = props;
  const {
    value,
    checked
  } = props;
  let name = props.name;
  if (props.register) {
    if (name === undefined) {
      name = props.register.name;
    }
    inputProps = {
      ...inputProps,
      ...props.register
    };
  }
  let inputId = ["RadioWithLabel", name, value].filter(value => value).join("-");
  if (idPrefix) {
    inputId = idPrefix + "-" + inputId;
  }
  inputProps = ({
    ...inputProps,
    id: inputId
  } as any);
  if (inputRef) {
    inputProps = ({
      ...inputProps,
      ref: inputRef
    } as any);
  } else if (ref) {
    inputProps = ({
      ...inputProps,
      ref
    } as any);
  }
  if (checked !== undefined) {
    inputProps = ({
      ...inputProps,
      checked
    } as any);
  }
  if (onDomChange) {
    inputProps = {
      ...(inputProps as any),
      onChange: onDomChange
    };
  } else if (onChange) {
    inputProps = ({
      ...inputProps,
      onChange: () => (onChange as any)(value)
    } as any);
  }
  if (onClick) {
    inputProps = ({
      ...inputProps,
      onClick: () => (onClick as any)(value)
    } as any);
  }
  return <div className={`${Style.radioWithLabel} ${className || ""}`}>
      <div>
        <input type="radio" {...inputProps} />
      </div>
      <label htmlFor={inputId} className={clsx("ml-2", inputProps.disabled && "text-secondary-400")}>
        {label}
        {required ? <>
            {" "}
            <RequiredFieldAsterisk />
          </> : null}
      </label>
    </div>;
});
export default RadioWithLabel;