interface Props {
  children?: React.ReactNode;
}
export default function MainMenuWrapper(props: Props) {
  return <div className="max-w-lg mx-auto" style={{
    maxWidth: "500px"
  }} data-sentry-component="MainMenuWrapper" data-sentry-source-file="MainMenuWrapper.tsx">
      <h2 className="h2 text-center mx-auto mb-8">
        Navigation and Content Management
      </h2>
      <div className="flex flex-col gap-1 mt-8 mx-auto" style={{
      maxWidth: "300px"
    }}>
        {props.children}
      </div>
    </div>;
}