import Link from "next/link";
import ArrowIcon from "components/icon/ArrowIcon";
import { ReactNode } from "react";
interface Props {
  children?: ReactNode;
  onClick?: () => void;
  href?: string;
  className?: string;
}
export default function BackArrow(props: Props) {
  const {
    children,
    className,
    href,
    onClick
  } = props;
  function MaybeLink({
    href,
    children
  }: {
    href: string | undefined;
    children: React.ReactNode;
  }) {
    if (href) {
      return <Link href={href} legacyBehavior>
          {children}
        </Link>;
    }
    return <>{children}</>;
  }
  return <MaybeLink href={href} data-sentry-element="MaybeLink" data-sentry-component="BackArrow" data-sentry-source-file="BackArrow.tsx">
      <a onClick={e => {
      if (onClick) {
        e.preventDefault();
        onClick();
      }
    }} className={`BackArrow mr-4 flex text-gray-darker hover:text-spp-violet-lighter cursor-pointer flex gap-2 items-center ${className || ""}`}>
        <div style={{
        width: "20px",
        minWidth: "20px",
        maxWidth: "20px",
        height: "20px",
        transform: "rotate(180deg)"
      }}>
          <ArrowIcon className="fill-current" data-sentry-element="ArrowIcon" data-sentry-source-file="BackArrow.tsx" />
        </div>
        {children}
      </a>
    </MaybeLink>;
}