import CloseIcon from "components/icon/CloseIcon";
import HamburgerMenuIcon from "components/icon/HamburgerMenuIcon";
import { useHamburgerStore } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
import Navigation from "./Navigation";
import { buildClassName } from "lib/string";
import { useEffect } from "react";
interface Props {
  className?: string;
  navMenuSlug?: string;
  parentTag?: string;
}
function PrimaryNavigation({
  className,
  navMenuSlug,
  parentTag
}: Props) {
  const store = useHamburgerStore();
  function onHamburgerClick() {
    store?.toggle();
  }
  useEffect(() => {
    if (navMenuSlug) {
      store?.setNavItemSlug(navMenuSlug || "");
    }
  }, []);
  return <div className={buildClassName(parentTag, "PrimaryNavigation", className, "transition")} data-sentry-component="PrimaryNavigation" data-sentry-source-file="PrimaryNavigation.tsx">
      <Navigation navMenuSlug={navMenuSlug} purpose="primary" data-sentry-element="Navigation" data-sentry-source-file="PrimaryNavigation.tsx" />
      <div className="NavigationMenuButton cursor-pointer" onClick={onHamburgerClick}>
        {store?.isOpen ? <CloseIcon className="fill-current" /> : <HamburgerMenuIcon className="fill-current" />}
      </div>
    </div>;
}
export default observer(PrimaryNavigation);