import Head from "next/head";
import { observer } from "mobx-react-lite";
import nextConfigFn from "next/config";
import { useGroupConfig } from "lib/gf-app-context";
import { pageTitle } from "lib/content";
import Script from "next/script";
import GoogleFonts from "./GoogleFonts";
// import HotJar from "./HotJar";

interface Props {
  title?: string;
}
function HtmlHead(props: Props) {
  const groupConfig = useGroupConfig();
  const {
    faviconUrl
  } = groupConfig;
  const config = nextConfigFn();
  const {
    squareSdkBaseUrl,
    release
  } = config.publicRuntimeConfig;
  const title = pageTitle(props.title, groupConfig);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="HtmlHead.tsx">
        <meta charSet="UTF-8" data-sentry-element="meta" data-sentry-source-file="HtmlHead.tsx" />
        <meta name="viewport" content="width=device-width, initial-scale=1" data-sentry-element="meta" data-sentry-source-file="HtmlHead.tsx" />
        <meta name="release" content={release} data-sentry-element="meta" data-sentry-source-file="HtmlHead.tsx" />
        <title>{title}</title>
        {faviconUrl && <link rel="shortcut icon" type="image/png" href={faviconUrl} />}
      </Head>
      <GoogleFonts groupConfig={groupConfig} data-sentry-element="GoogleFonts" data-sentry-source-file="HtmlHead.tsx" />
      <Script type="text/javascript" src={`${squareSdkBaseUrl}/v1/square.js`} data-sentry-element="Script" data-sentry-source-file="HtmlHead.tsx" />
      <Script src="https://js.stripe.com/v3/" data-sentry-element="Script" data-sentry-source-file="HtmlHead.tsx" />
      {/* <HotJar /> */}
    </>;
}
export default observer(HtmlHead);