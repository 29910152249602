import { ErrorMessage, TextInput } from "components/form";
import RowLabel from "components/profileEdit/RowLabel";
import { useHookFormAutoFocus } from "hooks/useHookFormAutoFocus";
import { EMAIL_PATTERN } from "lib/auth";
import { UseFormReturn } from "react-hook-form";
interface EmailForm {
  email: string;
}
interface Props {
  form: UseFormReturn<any>;
  onSubmit: (data: EmailForm) => void;
}
export default function EmailPrompt(props: Props) {
  const {
    onSubmit
  } = props;
  const form = (props.form as UseFormReturn<EmailForm>);
  const {
    formState: {
      errors
    },
    handleSubmit,
    register
  } = form;
  const wrapRegister = useHookFormAutoFocus();
  return <form onSubmit={handleSubmit(onSubmit)} className="relative" data-sentry-component="EmailPrompt" data-sentry-source-file="EmailPrompt.tsx">
      <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="EmailPrompt.tsx">Email address</RowLabel>
      <div className="flex gap-x-2">
        <TextInput type="email" register={wrapRegister(register("email", {
        required: true,
        pattern: EMAIL_PATTERN
      }))} placeholder="you@example.com" data-sentry-element="TextInput" data-sentry-source-file="EmailPrompt.tsx" />
        <button type="submit" className="Button">
          Next
        </button>
      </div>
      <ErrorMessage name="email" errors={errors} messages={{
      pattern: "email must be a valid email address"
    }} data-sentry-element="ErrorMessage" data-sentry-source-file="EmailPrompt.tsx" />
    </form>;
}