// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React, { LegacyRef } from "react";
import Styles from "lib/Style";
interface Props {
  className?: string;
  name?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  innerRef?: LegacyRef<HTMLTextAreaElement>;
  register?: {
    [index: string]: any;
  };
  style?: any;
}
const TextArea = (props: Props) => {
  const initProps = {};
  const className = props.className ?? Styles.textArea;
  let {
    innerRef,
    ...props2
  } = props;
  let elProps: any = {
    ...initProps,
    ...props2
  };
  if (innerRef) {
    elProps = {
      ...elProps,
      ref: innerRef
    };
  }
  if (props.register) {
    elProps = {
      ...elProps,
      ...props.register
    };
  }
  if (props.placeholder) {
    elProps = {
      ...elProps,
      placeholder: props.placeholder
    };
  }
  return <textarea {...elProps} className={className} data-sentry-component="TextArea" data-sentry-source-file="TextArea.tsx" />;
};
export default TextArea;