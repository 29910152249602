// Automatically focus on a react-hook-form input field when the component mounts.

import { useRef } from "react";
export function useHookFormAutoFocus() {
  const inputFocused = useRef(false);
  const newInputRef = function (el: HTMLElement | null, register: any) {
    register.ref(el);
    if (el && !inputFocused.current) {
      inputFocused.current = true;
      el.focus();
    }
  };
  const wrapRegister = (register: any) => {
    return {
      ...register,
      ref: (el: HTMLElement) => newInputRef(el, register)
    };
  };
  return wrapRegister;
}