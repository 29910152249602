// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import App from "next/app";
import type { AppProps, AppContext } from "next/app";
import { CookiesProvider } from "react-cookie";
import { ApolloProvider } from "@apollo/client";
import { getInitialProps } from "lib/initial-props";
import { MpAppContext } from "lib/gf-app-context";
import { InitialPageProps } from "types/gf-app-context";
import useAppBootstrap from "hooks/useAppBootstrap";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/styles.css";
import clsx from "clsx";
import Alert from "components/util/Alert";
import HtmlHead from "components/layout/HtmlHead";
import Clickstream from "components/layout/Clickstream";
function GroupFlowWeb(appProps: AppProps & {
  pageProps: InitialPageProps;
}) {
  const context = useAppBootstrap(appProps);
  const {
    getLayout,
    component: Component,
    appContext
  } = context;
  const {
    themeInfo: {
      areaClass
    }
  } = appContext;
  function maybeApollo(apollo: any, children: React.ReactNode) {
    if (apollo) {
      return <ApolloProvider client={apollo}>{children}</ApolloProvider>;
    }
    return children;
  }
  return <MpAppContext.Provider value={appContext} data-sentry-element="unknown" data-sentry-component="GroupFlowWeb" data-sentry-source-file="_app.tsx">
      <CookiesProvider data-sentry-element="CookiesProvider" data-sentry-source-file="_app.tsx">
        <Clickstream data-sentry-element="Clickstream" data-sentry-source-file="_app.tsx" />
        <HtmlHead data-sentry-element="HtmlHead" data-sentry-source-file="_app.tsx" />
        {maybeApollo(appContext.apollo, <>
            <div className={clsx("font-sans gf-page", areaClass)}>
              {getLayout(<Component {...appContext} />)}
            </div>
            <Alert />
          </>)}
      </CookiesProvider>
    </MpAppContext.Provider>;
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.

GroupFlowWeb.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  let appProps = await App.getInitialProps(appContext);
  let {
    ctx
  } = appContext;
  let pageProps: Partial<InitialPageProps> = {
    groupSlug: undefined,
    groupConfig: undefined,
    jwt: undefined,
    groups: null
  };
  const moreProps: InitialPageProps = await getInitialProps(ctx.req);
  pageProps = {
    ...appProps.pageProps,
    ...moreProps
  };
  return {
    ...appProps,
    pageProps
  };
};
export default GroupFlowWeb;