import Badge from "./Badge";
interface Props {
  items: string[];
  formatItem?: (item: string) => string | React.ReactNode;
}
export default function BadgeList(props: Props) {
  const {
    items,
    formatItem
  } = props;
  return <>
      {items.map(item => <Badge key={item}>{formatItem ? formatItem(item) : item}</Badge>)}
    </>;
}