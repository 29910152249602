import { SVGProps } from "react";
interface Props extends SVGProps<SVGSVGElement> {
  style?: any;
  className?: string;
}
export default function MessageIcon(props: Props) {
  const {
    className,
    style,
    ...otherProps
  } = props;
  return <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} className={className} {...otherProps} data-sentry-element="svg" data-sentry-component="MessageIcon" data-sentry-source-file="MessageIcon.tsx">
      <path d="M5.16667 5.16667H11.8333M5.16667 8.5H10.1667M6 12.6667H3.5C2.83696 12.6667 2.20107 12.4033 1.73223 11.9344C1.26339 11.4656 1 10.8297 1 10.1667V3.5C1 2.83696 1.26339 2.20107 1.73223 1.73223C2.20107 1.26339 2.83696 1 3.5 1H13.5C14.163 1 14.7989 1.26339 15.2678 1.73223C15.7366 2.20107 16 2.83696 16 3.5V10.1667C16 10.8297 15.7366 11.4656 15.2678 11.9344C14.7989 12.4033 14.163 12.6667 13.5 12.6667H11L8.5 15.1667L6 12.6667Z" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MessageIcon.tsx" />
    </svg>;
}