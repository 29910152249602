// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { useEffect, useRef, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { ApiError, ApiResponse } from "types/api";
import { EditableFieldsProps, FieldEditing, MpFormProps, UsingMpForm } from "types/fieldEditing";

// For managing a set of fields, where each field has its own separate form.
export function useFieldsEditing(props: EditableFieldsProps): FieldEditing {
  const [currentFieldName, setCurrentFieldName] = useState<string | null>(null);
  async function handleSubmit(values: any): Promise<ApiResponse<any> | null> {
    if (!props.onSubmit) return null;
    const result = await props.onSubmit(values);
    if (!result?.error) {
      setTimeout(() => setCurrentFieldName(null), 20);
    }
    return result;
  }
  return {
    currentFieldName,
    handleSubmit,
    setCurrentFieldName
  };
}
export function useMpForm<FieldsType extends FieldValues>(props: MpFormProps): UsingMpForm {
  const {
    hookFormProps,
    initialValues
  } = props;
  const hookForm = useForm<FieldsType>(hookFormProps || {});
  const {
    handleSubmit,
    setValue
  } = hookForm;
  const setInitalValues = useRef(false);
  const [saveError, setSaveError]: [null | ApiError, (v: null | ApiError) => void] = useState((null as null | ApiError));
  const [saving, setSaving]: [boolean, (v: boolean) => void] = useState((false as boolean));
  async function onSubmit(values: any): Promise<ApiResponse<any> | null> {
    if (!props.onSubmit) return null;
    setSaving(true);
    const result = await props.onSubmit(values);
    setSaving(false);
    if (result?.error) {
      setSaveError(result);
    }
    return result;
  }
  useEffect(() => {
    if (initialValues && !setInitalValues.current) {
      const keys = Object.keys(initialValues);
      keys.forEach(key => {
        setValue((key as any), (initialValues as any)[key]);
      });
      setInitalValues.current = true;
    }
  }, [initialValues]);
  return {
    hookForm,
    onSubmit,
    saving,
    saveError,
    handleSubmit: handleSubmit(onSubmit)
  };
}