// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  type?: string;
  children?: React.ReactNode;
  className?: string;
}
export default function FieldColumn(props: Props) {
  const {
    type,
    children
  } = props;
  const blockClass = type === "block" ? "block" : "inline-block";
  const className = `${blockClass} ${props.className || ""}`;
  return <div className={className} data-sentry-component="FieldColumn" data-sentry-source-file="FieldColumn.tsx">{children}</div>;
}