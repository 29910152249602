// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { timeFormat } from "lib/constants";
import { formatDate } from "lib/date";
import TimezoneOffset from "./TiimezoneOffset";
interface Props {
  dateTime: Date | string;
}
export default function TimeWithOffset(props: Props) {
  return <div data-sentry-component="TimeWithOffset" data-sentry-source-file="TimeWithOffset.tsx">
      {formatDate(props.dateTime, timeFormat)}{" "}
      <TimezoneOffset date={props.dateTime} data-sentry-element="TimezoneOffset" data-sentry-source-file="TimeWithOffset.tsx" />
    </div>;
}