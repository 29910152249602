import { useContentEditStore, useMemberJwt } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
import { Styles } from "react-modal";
import MainMenuWrapper from "./MainMenuWrapper";
import NavManagement from "./NavManagement";
import TextManagement from "./TextManagement";
import { ADMIN_STYLE } from "lib/Style";
import clsx from "clsx";
import SimpleModal from "components/SimpleModal";
import BackButtonWithLabel from "components/ui/BackButtonWithLabel";
interface Props {
  onRequestClose?: () => void;
}
function ContentEditModal(props: Props) {
  const {
    onRequestClose
  } = props;
  const contentEditStore = useContentEditStore();
  const jwt = useMemberJwt();
  if (!jwt) return null;
  let modalStyle = (ADMIN_STYLE.modalStyle as any);
  modalStyle = ({
    ...ADMIN_STYLE.modalStyle,
    content: {
      ...modalStyle.content,
      maxWidth: "1000px",
      minHeight: "400px",
      padding: 0
    }
  } as Styles);
  function onClose() {
    contentEditStore?.onEditingClose();
    onRequestClose && onRequestClose();
  }
  function onBaseTypeClose() {
    contentEditStore?.back();
  }
  const navItem = contentEditStore?.currentNavItem();
  const isOpen = !!navItem;
  const baseType = navItem?.baseType;
  const showMenu = !baseType;
  const buttonClass = clsx(ADMIN_STYLE.buttonShapeMedium, "border border-new-gray-200 hover:border-black shadow text-secondary-950 hover:text-black");
  return <SimpleModal isOpen={isOpen} onClose={onClose} className="gf-customer gf-area-members" modalStyle={modalStyle} themeArea="admin" data-sentry-element="SimpleModal" data-sentry-component="ContentEditModal" data-sentry-source-file="ContentEditModal.tsx">
      <div className="h-full flex-1">
        <div>
          {showMenu && <div className="flex float-left cursor-pointer" onClick={onClose}>
              <BackButtonWithLabel onClick={onClose} />
              <span className="cursor-pointer">Close</span>
            </div>}
        </div>

        {showMenu ? <MainMenuWrapper>
            <button onClick={() => contentEditStore?.showSearch("nav")} className={buttonClass}>
              Navigation
            </button>
            <button onClick={() => contentEditStore?.showSearch("text")} className={buttonClass}>
              Text Content
            </button>
          </MainMenuWrapper> : <>
            {baseType === "nav" && <NavManagement onClose={onBaseTypeClose} />}
            {baseType === "text" && <TextManagement />}
          </>}
      </div>
    </SimpleModal>;
}
export default observer(ContentEditModal);