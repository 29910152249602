// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { useJwtStore, useMeetupConfig } from "lib/gf-app-context";
import { useState } from "react";
import VerifyEmail from "components/registerMember/VerifyEmail";
import SimpleModal from "components/SimpleModal";
import { Me } from "types/member";
import { observer } from "mobx-react-lite";
interface Props {
  user: Me | undefined | null;
}

// This is for the case where a Member is logged in (via SSO), but
// they don't have an email address registered
function RegistrationRequired(props: Props) {
  const {
    user
  } = props;
  const jwtStore = useJwtStore();
  const [isModalOpen, setIsModalOpen]: [boolean, (v: boolean) => void] = useState((true as boolean));
  const memberJwt = jwtStore?.memberJwt;
  const meetup = useMeetupConfig();
  if (!memberJwt) return null;
  if (user && user.account.email) return null;
  return <SimpleModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} data-sentry-element="SimpleModal" data-sentry-component="RegistrationRequired" data-sentry-source-file="RegistrationRequired.tsx">
      <h1 className="h1">You Are Not Registered</h1>

      <p className="p">
        Many functions of this website are disabled until your email address is
        registered.
      </p>

      <VerifyEmail jwt={memberJwt} returnToUri={meetup?.authEnabled ? "/register-member/meetup" : "/register-member/email"} data-sentry-element="VerifyEmail" data-sentry-source-file="RegistrationRequired.tsx" />

      <p className="p">
        A confirmation email will be sent to the address you provide.
      </p>
    </SimpleModal>;
}
export default observer(RegistrationRequired);