// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  children: React.ReactNode;
}
export default function TitleWrapper(props: Props) {
  const {
    children
  } = props;
  return <div className="flex justify-between items-center mb-4" data-sentry-component="TitleWrapper" data-sentry-source-file="TitleWrapper.tsx">{children}</div>;
}