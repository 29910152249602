// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import FieldModalBasics from "./FieldModalBasics";
import { FieldEditing } from "types/fieldEditing";
import FieldEditButton from "./FieldEditButton";
interface Props {
  button?: React.ReactNode;
  label?: string;
  children?: React.ReactNode;
  fieldEditing: FieldEditing;
  name: string;
}
export default function EditButtonWithModal(props: Props) {
  const {
    children,
    fieldEditing,
    label,
    name
  } = props;
  const {
    currentFieldName,
    setCurrentFieldName
  } = fieldEditing;
  return <>
      {props.button || <FieldEditButton fieldEditing={fieldEditing} name={name} label={label} />}
      <FieldModalBasics isOpen={currentFieldName === name} onClose={() => setCurrentFieldName(null)} data-sentry-element="FieldModalBasics" data-sentry-source-file="EditButtonWithModal.tsx">
        {children}
      </FieldModalBasics>
    </>;
}