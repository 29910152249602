import { UseFormReturn } from "react-hook-form";
import { ErrorMessage, RowLabel, FormRow } from ".";
interface Props {
  children: React.ReactNode;
  errorMessages?: {
    [index: string]: string;
  };
  hookForm?: UseFormReturn<any>;
  label?: string;
  name: string;
  errors?: any;
}
export default function FieldEditRow(props: Props) {
  const {
    children,
    errorMessages,
    hookForm,
    label,
    name
  } = props;
  const errors = props.errors || hookForm?.formState?.errors;
  return <FormRow data-sentry-element="FormRow" data-sentry-component="FieldEditRow" data-sentry-source-file="FieldEditRow.tsx">
      {label && <RowLabel>{label}</RowLabel>}
      {children}
      <ErrorMessage errors={errors} name={name} messages={errorMessages} data-sentry-element="ErrorMessage" data-sentry-source-file="FieldEditRow.tsx" />
    </FormRow>;
}