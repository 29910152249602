import { ContentItem } from "types/content";
import ContentItemTypeIcon from "./ContentItemTypeIcon";
import StatusIcon from "./StatusIcon";
import TextSubTypeHeading from "./TextSubTypeHeading";
interface Props {
  contentItem: ContentItem;
}
export default function TextItemResultItem(props: Props) {
  const {
    contentItem
  } = props;
  return <div title={`ID: ${contentItem.id}`} className="flex gap-1 items-center" data-sentry-component="TextItemResultItem" data-sentry-source-file="TextItemResultItem.tsx">
      <ContentItemTypeIcon contentItem={contentItem} data-sentry-element="ContentItemTypeIcon" data-sentry-source-file="TextItemResultItem.tsx" />
      <StatusIcon contentItem={contentItem} data-sentry-element="StatusIcon" data-sentry-source-file="TextItemResultItem.tsx" />
      <div className="ml-2 flex gap-1">
        <div>
          <TextSubTypeHeading plurality={contentItem.plurality} subType={contentItem.subType} data-sentry-element="TextSubTypeHeading" data-sentry-source-file="TextItemResultItem.tsx" />
        </div>
        <div>{contentItem.title}</div>
        <div className="text-gray-dark">{contentItem.internalName}</div>
      </div>
    </div>;
}