/*!
 * ContentSection (Post) plugin for WebComponents
 */

import clsx from "clsx";
import MarkdownContent from "components/MarkdownContent";
import EditableSection from "components/contentEditing/EditableSection";
import { usePosts } from "hooks/content";
import { observer } from "mobx-react-lite";
interface Props {
  className?: string;
  contentSlug?: string;
}
const ContentSnippet = (props: Props) => {
  const {
    className,
    contentSlug
  } = props;
  const {
    getPostListBySlug: getPost
  } = usePosts();
  const post = contentSlug ? getPost(contentSlug) : undefined;
  function renderContent() {
    if (!post?.content) return null;
    if (post.contentType === "text/html") {
      return <div className="inner" dangerouslySetInnerHTML={{
        __html: post.content
      }} />;
    } else if (post.contentType === "text/markdown") {
      return <MarkdownContent content={post.content} />;
    }
  }
  return <EditableSection contentId={post?.id} className={clsx("ContentSnippet EditableSection", className)} data-sentry-element="EditableSection" data-sentry-component="ContentSnippet" data-sentry-source-file="ContentSnippet.tsx">
      {renderContent()}
    </EditableSection>;
};
export default observer(ContentSnippet);