// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import MarkdownContent from "./MarkdownContent";
import TextToHtml from "./ui/TextToHtml";
interface Props {
  text: string;
  contentType?: "text/plain" | "text/markdown" | "text/html";
  format?: "plain" | "markdown" | "html";
}
export default function FormattedText(props: Props) {
  let {
    contentType,
    format,
    text
  } = props;
  if (contentType === "text/markdown") {
    format = "markdown";
  } else if (contentType === "text/html") {
    format = "html";
  } else if (contentType === "text/plain") {
    format = "plain";
  }
  format = format || "markdown";
  if (format === "markdown") {
    return <MarkdownContent content={text} />;
  } else if (format === "html") {
    return <span className="external-content" dangerouslySetInnerHTML={{
      __html: text
    }} />;
  }
  return <span className="external-content" data-sentry-component="FormattedText" data-sentry-source-file="FormattedText.tsx">
      <TextToHtml text={text} data-sentry-element="TextToHtml" data-sentry-source-file="FormattedText.tsx" />
    </span>;
}