/**
 * Deprecated: Use CheckboxWithLabel3 instead.
 */

import { SyntheticEvent } from "react";
import FieldOptionLayout from "./FieldOptionLayout";
import FieldRequiredAsterisk from "./FieldRequiredAsterisk";
interface Props {
  checked?: boolean;
  label: string | React.ReactNode;
  name?: string;
  onClick?: () => void;
  onChange?: (ev: SyntheticEvent) => void;
  register?: any;
  required?: boolean;
}

/**
 * CheckboxWithLabel2 fixes a bug with hookForm for boolean values.
 */
export default function CheckboxWithLabel2(props: Props) {
  let {
    register,
    required,
    label
  } = props;
  let name1;
  if (register) {
    props = {
      ...props,
      ...register
    };
    const {
      name: name2
    } = register;
    if (name2) {
      name1 = name2;
    }
  } else {
    name1 = props.name;
  }
  const inputId = ["CheckboxWithLabel", name1, "true"].filter(value => value).join("-");
  return <FieldOptionLayout field={<input type="checkbox" {...props} id={inputId} />} label={<label htmlFor={inputId}>
          {label}
          {required ? <>
              {" "}
              <FieldRequiredAsterisk />
            </> : null}
        </label>} data-sentry-element="FieldOptionLayout" data-sentry-component="CheckboxWithLabel2" data-sentry-source-file="CheckBoxWithLabel2.tsx" />;
}