// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  children: React.ReactNode;
}
export default function LabelCol(props: Props) {
  const {
    children
  } = props;
  return <div className="flex-grow cursor-pointer hover:text-gf-green-dark ml-2 whitespace-nowrap whitespace-nowrap truncate flex justify-left items-center" data-sentry-component="LabelCol" data-sentry-source-file="LabelCol.tsx">
      {children}
    </div>;
}