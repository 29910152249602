import { buildNavMenu, buildPostListItem } from "lib/content";
import { useContentStore } from "lib/gf-app-context";
import { useEffect } from "react";
import ContentStore from "stores/ContentStore";
import { ContentItem, ContentItemDoubleExtended, ContentItemExtended } from "types/content";
export const usePosts = () => {
  const contentStore = useContentStore();
  const getPostListBySlug1 = (id: string): ContentItemDoubleExtended | undefined => {
    return getPostListById(id, contentStore);
  };
  const getPostListBySlug2 = (slug: string): ContentItemDoubleExtended | undefined => {
    return getPostListBySlug(slug, contentStore);
  };
  useEffect(() => {
    if (!contentStore.navItems) {
      contentStore.loadNavigationData();
    }
    if (!contentStore.textItems) {
      contentStore.getTextItems();
    }
  }, [contentStore.textItems]);
  return {
    getPostListBySlug: getPostListBySlug2,
    getPostListById: getPostListBySlug1
  };
};
export function getPostListById(id: string, contentStore: ContentStore) {
  let postsItem = getBareTextItemById(id, contentStore);
  return postsItem && buildPost(postsItem, contentStore);
}
export function getPostListBySlug(slug: string, contentStore: ContentStore) {
  let postsItem = getBareTextItemBySlug(slug, contentStore);
  return postsItem && buildPost(postsItem, contentStore);
}
function buildPost(postsItem: ContentItem, contentStore: ContentStore) {
  const textItems = contentStore.textItems || undefined;
  const navItems = contentStore.navItems || undefined;
  const postsItem2 = postsItem && textItems && navItems && buildPostListItem(postsItem, textItems, navItems);
  return postsItem2;
}
function getBareTextItemById(id: string, contentStore: ContentStore): ContentItem | undefined {
  return contentStore.textItems?.find(t => t.id === id);
}
function getBareTextItemBySlug(slug: string, contentStore: ContentStore): ContentItem | undefined {
  return contentStore.textItems?.find(t => t.slug === slug);
}
export const useNavItem = () => {
  const contentStore = useContentStore();
  const getNavMenu = (slug: string): ContentItemExtended | undefined => {
    if (!contentStore.navItems) return undefined;
    const item = contentStore?.navItems?.find(t => t.slug === slug);
    if (!item) return undefined;
    return buildNavMenu(item, contentStore.navItems);
  };
  const getNavMenuById = (id: string): ContentItemExtended | undefined => {
    if (!contentStore.navItems) return undefined;
    const item = contentStore?.navItems?.find(t => t.id === id);
    if (!item) return undefined;
    return buildNavMenu(item, contentStore.navItems);
  };
  useEffect(() => {
    if (!contentStore.navItems) {
      contentStore.loadNavigationData();
    }
  }, []);
  return {
    getNavMenu,
    getNavMenuById
  };
};