import React, { useRef } from "react";
import { FileInput, RowLabel, TextInput } from "components/form";
import GenericImageIcon from "components/icon/GenericImageIcon";
import ProgressIndicator from "components/ProgressIndicator";
import { postContentPhoto } from "lib/gf-api";
import { useApiHelper } from "lib/gf-api/api-react";
import { SimpleImageUpload } from "types/photo";
import ApiErrors from "components/ApiErrors";
import { observer } from "mobx-react-lite";
import HollowButton from "components/admin/ui/HollowButton";
import { TrashIcon } from "@heroicons/react/24/outline";
interface PhotoValues {
  id: string | null;
  photoId: string | null;
  content: string;
  url: string | null;
}
interface Props {
  photoValues?: PhotoValues;
  captionRegister?: any;
  onPhotoUploaded?: ({
    photoId,
    url
  }: {
    photoId: string;
    url: string;
  }) => void;
  onRemovePhoto?: () => void;
  photoIdRegister?: any;
}
const fileInputRef: React.RefObject<HTMLInputElement | null> = React.createRef();
function PhotoUploadField(props: Props) {
  const {
    captionRegister,
    photoIdRegister,
    photoValues
  } = props;
  const alt = photoValues?.content;
  const photoUrl = photoValues?.url;
  const photoRef = useRef((null as null | HTMLImageElement));
  const photoUpload = useApiHelper<SimpleImageUpload>();
  function setFile(file: File) {
    onSubmit(file);
  }
  function uploader() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }
  async function onSubmit(file: File): Promise<void> {
    const result = await photoUpload.wrapCall(call => postContentPhoto(call, file));
    if (!result.error) {
      const {
        id: photoId,
        url
      } = result.data;
      props.onPhotoUploaded && props.onPhotoUploaded({
        photoId,
        url
      });
    }
  }
  const imageStyle: any = {
    maxWidth: "200px",
    maxHeight: "200px",
    position: "relative"
  };
  function photoCmp() {
    if (photoUrl) {
      return <div className="bg-white" style={{
        background: `url(https://mp1md-pub.s3.amazonaws.com/groupflow/checker-20.png)`,
        backgroundRepeat: "repeat"
      }}>
          <img ref={photoRef} alt={alt || undefined} src={photoUrl} style={imageStyle} className="object-contain" />
        </div>;
    } else {
      return <GenericImageIcon className="fill-current text-gray" />;
    }
  }
  function onRemovePhotoClick() {
    props.onRemovePhoto && props.onRemovePhoto();
  }
  return <div className="flex justify-between gap-4" data-sentry-component="PhotoUploadField" data-sentry-source-file="PhotoUploadField.tsx">
      <div style={{
      maxWidth: imageStyle.maxWidth,
      minWidth: "200px"
    }} className="flex flex-col gap-2">
        {photoCmp()}
        <HollowButton onClick={onRemovePhotoClick} colorStyle="warning" data-sentry-element="HollowButton" data-sentry-source-file="PhotoUploadField.tsx">
          <TrashIcon className="w-5 h-5" data-sentry-element="TrashIcon" data-sentry-source-file="PhotoUploadField.tsx" />
          Remove Photo
        </HollowButton>
      </div>
      <div className="flex-grow flex flex-col gap-2 justify-between">
        <div>
          {photoUpload.processing ? <div>
              Uploading
              <ProgressIndicator className="mt-2" />
            </div> : <div className="flex gap-4">
              <HollowButton onClick={uploader} colorStyle="primary">
                Select {photoUrl ? "a new" : "a"} photo...
              </HollowButton>
            </div>}
          <FileInput onChange={setFile} className="hidden" name="photoFile" inputRef={fileInputRef} data-sentry-element="FileInput" data-sentry-source-file="PhotoUploadField.tsx" />
          <input type="hidden" {...photoIdRegister || {}} />
          <ApiErrors error={photoUpload.errorResponse} data-sentry-element="ApiErrors" data-sentry-source-file="PhotoUploadField.tsx" />
        </div>
        <div>
          <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="PhotoUploadField.tsx">Photo caption</RowLabel>
          <TextInput {...captionRegister || {}} data-sentry-element="TextInput" data-sentry-source-file="PhotoUploadField.tsx" />
        </div>
      </div>
    </div>;
}
export default observer(PhotoUploadField);