import LinkButton from "components/LinkButton";
export default function ActiveMemberRequired() {
  return <div data-sentry-component="ActiveMemberRequired" data-sentry-source-file="ActiveMemberRequired.tsx">
      <h1 className="h1">Expired Membership</h1>
      <p className="p">
        Your membership has expired. Access to this page requires an active
        membership. Please visit the dues page to renew your membership.
      </p>
      <div className="flex justify-center mt-8">
        <LinkButton href="/members/dues" data-sentry-element="LinkButton" data-sentry-source-file="ActiveMemberRequired.tsx">Dues Page</LinkButton>
      </div>
    </div>;
}