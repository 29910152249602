import Flash from "components/Flash";
import { useEffect, useState } from "react";
import { MutationResult } from "types/mutation";
interface Props {
  mutationResult: MutationResult | undefined | null;
  className?: string;
}
export default function SaveResult(props: Props) {
  const {
    className
  } = props;
  const [mutationResult, setMutationResult]: [MutationResult | undefined | null, (v: MutationResult | undefined | null) => void] = useState();
  useEffect(() => {
    setMutationResult(props.mutationResult);
  }, [props.mutationResult]);
  if (!mutationResult) {
    return null;
  }
  let type = "warning";
  let defaultMessage;
  if (mutationResult.type === "error") {
    defaultMessage = "Encountered an error servicing your request.";
  } else if (mutationResult.type === "invalid") {
    defaultMessage = "Encountered an error processing the form submission. " + "Please check for any issues with the form and try again.";
  } else {
    type = "info";
    defaultMessage = "Successfully saved";
  }
  return <Flash className={className} type={(type as any)} onClose={() => setMutationResult(undefined)} data-sentry-element="Flash" data-sentry-component="SaveResult" data-sentry-source-file="SaveResult.tsx">
      {mutationResult.message || defaultMessage}
    </Flash>;
}