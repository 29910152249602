// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import GripIcon from "components/icon/GripIcon";
export default function GripCol(props: any) {
  const sortable = props.sortable || {};
  const id = props.id;
  return <div title="Drag to change its position" className="grip-control text-gray hover:text-gf-green-dark flex items-center" id={id} {...sortable} data-sentry-component="GripCol" data-sentry-source-file="GripCol.tsx">
      <GripIcon style={{
      width: "16px",
      height: "16px",
      fill: "currentColor"
    }} className="mx-2 cursor-pointer" data-sentry-element="GripIcon" data-sentry-source-file="GripCol.tsx" />
    </div>;
}