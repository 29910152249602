import LinkIcon from "components/icon/LinkIcon";
import ListIcon from "components/icon/ListIcon";
import OrgChartIcon from "components/icon/OrgChartIcon";
import PageIcon from "components/icon/PageIcon";
import QuestionMark from "components/icon/QuestionMark";
import { MiniContentItem } from "types/content";
interface Props {
  contentItem: Partial<MiniContentItem>;
}
const itemTypeIcons: {
  [index: string]: {
    [index: string]: any;
  };
} = {
  nav: {
    multi_list: OrgChartIcon,
    list: ListIcon,
    item: LinkIcon
  },
  text: {
    multi_list: OrgChartIcon,
    list: ListIcon,
    item: PageIcon
  }
};
const iconClass = "fill-current text-gray-dark";
const iconStyle = {
  width: "18px",
  height: "18px"
};
export default function ContentItemTypeIcon(props: Props) {
  const {
    contentItem: item
  } = props;
  const {
    baseType,
    plurality,
    subType
  } = item;
  let Cmp = QuestionMark;
  if (baseType && plurality && itemTypeIcons[baseType] && itemTypeIcons[baseType][plurality]) {
    Cmp = itemTypeIcons[baseType][plurality];
  }
  let title = "Link";
  if (plurality === "multi_list") {
    title = "Multi-List";
  } else if (plurality === "list") {
    title = "List";
  } else if (subType === "page") {
    title = "Page";
  } else if (subType === "post") {
    title = "Post";
  } else if (subType === "block") {
    title = "Block";
  }
  return <div title={title} style={{
    cursor: "help"
  }} data-sentry-component="ContentItemTypeIcon" data-sentry-source-file="ContentItemTypeIcon.tsx">
      <Cmp className={iconClass} style={iconStyle} data-sentry-element="Cmp" data-sentry-source-file="ContentItemTypeIcon.tsx" />
    </div>;
}